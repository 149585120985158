import {
  collection,
  query,
  onSnapshot,
  where,
  startAfter,
  // limit,
  getDocs,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// const LIMIT = 100;

export const fetchUserCount = async (user=null, isAdmin=false, isSales=false, isOps=false) => {
  let querySnapshot = 0;
  if(isAdmin || isSales || isOps) {
    const countQuery = query(
      collection(db, "users"),
    );
    querySnapshot = await getDocs(countQuery);
  }else{
    const countQuery = query(
      collection(db, "users"),
      where("assign", "==", user)
    );
    querySnapshot = await getDocs(countQuery);
  }
  const assignQuery = query(
    collection(db, "users"),
    where("assign", "!=", null)
  );
  const querySnapshots = await getDocs(assignQuery);

  return querySnapshot.size - querySnapshots.size;
};

export const fetchPunchCount = async (user=null, isAdmin=false, isSales =false, isOps=false) => {
  let querySnapshot = 0;
  if(isAdmin || isSales || isOps) {
    const punchQuery = query(
      collection(db, "2024-25"),
      where("punch", "==", true)
    );
    querySnapshot = await getDocs(punchQuery);
  }else {
    const punchQuery = query(
      collection(db, "2024-25"),
      where("punch", "==", true),
      where("assign", "==", user)
    );
    querySnapshot = await getDocs(punchQuery);
  
  }
  return querySnapshot.size;
};



export const fetchAssignedcount = async () => {
  const assignQuery = query(
    collection(db, "users"),
    where("assign", "!=", null)
  );
  const querySnapshot = await getDocs(assignQuery);
  return querySnapshot.size;
}

export const fetch = async (userId, punched = false, isAdmin = false, isSales=false, isOps=false, isAssigned=false) => {
  // alert(isAdmin);
  if (!punched) {
    let q = "";
    if(isAdmin || isSales || isOps) {
      // console.log(isAssigned);
      if(isAssigned) {

        q = query(
          collection(db, "users"),
          where("assign", "!=", null),
          // orderBy("pan"),
          // limit(LIMIT)
        );
        // console.log("assigned");
      }else{
        q = query(
          collection(db, "users"),
          orderBy("pan"),
          // limit(LIMIT)
        );
      }
    }else{
      q = query(
        collection(db, "users"),

        where("assign", "==", userId),
        orderBy("pan"),
        // limit(LIMIT)
      ); 
    }
    const documentSnapshots = await getDocs(q);
    const data = documentSnapshots.docs.map((doc) => doc.data());
    const newData = data.map(async (userData) => {
      const vRef = doc(db, process.env.REACT_APP_CURRENT_YEAR, userData.pan);
      const vSnapshot = await getDoc(vRef);
      // console.log("Inside Status:", vSnapshot.data().assign);
      let isVerified = vSnapshot.exists() ? vSnapshot.data().isVerified : false;
      // try{
      //   // console.log(vSnapshot.data().assign);
      //   userData.assign = vSnapshot.data().assign;
      // } catch(error) {
      //   console.log("No assign value found");
      // }
      const data = { ...userData, isVerified: isVerified };
      // console.log("User data: ", data);
      return data;
    });
    
    const newFetch = await Promise.all(newData);
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    return { newFetch, lastVisible };
  } else {
    let q ="";
    if(isAdmin || isSales || isOps) {
      // console.log(userId);
      q = query(
        collection(db, "2024-25"),
        where("punch", "==", true),
        // limit(LIMIT)
      );
    }else {
      q = query(
        collection(db, "2024-25"),
        where("punch", "==", true),
        where("assign", "==", userId),
        orderBy("pan"),
        // limit(LIMIT)
      );
    }
    const querySnapshot = await getDocs(q);

    const userIds = querySnapshot.docs.map((doc) => {
      const data = [doc.id, doc.data().isVerified];
      return data;
    });

    const usersPromises = userIds.map(async (userId) => {
      const userRef = doc(db, "users", userId[0]);
      const userSnapshot = await getDoc(userRef);
      const data = { ...userSnapshot.data(), isVerified: userId[1] };
      return data;
    });

    const newFetch = await Promise.all(usersPromises);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { newFetch, lastVisible };
  }
};


export const fetchNext = async (userId, tasks, lastVisible, punched = false, isAdmin = false, isSales = false, isOps=false) => {
  //   console.log(punched, lastVisible);
  let lastResponse = false;
  let newFetch = [];
  let lastVisibleNext;
  if (!punched) {
    const next = "";
    if(isAdmin || isSales || isOps) {
      query(
        collection(db, "users"),
        orderBy("pan"),
        startAfter(lastVisible),
        // limit(LIMIT)
      );
    } else {
      query(
        collection(db, "users"),
        where("assign", "==", userId),
        orderBy("pan"),
        startAfter(lastVisible),
      );
    }
    const documentSnapshots = await getDocs(next);
    const data = documentSnapshots.docs.map((doc) => doc.data());
    const newData = data.map(async (userData) => {
      const vRef = doc(db, "2024-25", userData.pan);
      const vSnapshot = await getDoc(vRef);
      let isVerified = vSnapshot.exists() ? vSnapshot.data().isVerified : false;
      const data = { ...userData, isVerified: isVerified };
      return data;
    });
    newFetch = await Promise.all(newData);
    lastVisibleNext = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  } else {
    const next = query(
      collection(db, "2024-25"),
      where("punch", "==", true),
      orderBy("pan"),
      startAfter(lastVisible),
      // limit(LIMIT)
    );
    const querySnapshot = await getDocs(next);

    const userIds = querySnapshot.docs.map((doc) => {
      const data = [doc.id, doc.data().isVerified];
      return data;
    });

    const usersPromises = userIds.map(async (userId) => {
      const userRef = doc(db, "users", userId[0]);
      const userSnapshot = await getDoc(userRef);
      const data = { ...userSnapshot.data(), isVerified: userId[1] };
      return data;
    });

    newFetch = await Promise.all(usersPromises);
    lastVisibleNext = querySnapshot.docs[querySnapshot.docs.length - 1];
  }
  if (newFetch.length === 0) {
    lastResponse = true;
  }
  const newTasks = [...tasks, ...newFetch];
  return { newTasks, lastVisibleNext, lastResponse };
};

