import { PDFDocument, rgb } from "pdf-lib"
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
const { toWords } = require("number-to-words");

function numberToWords(num) {
  const words = toWords(num).toLowerCase().split(" ");
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(" "); // Convert to uppercase for better readability
}

function getFormattedDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

export const modifyAndDownloadPDF = async (pan) => {
  try {
    console.log("Pan:", pan); // Check the value of pan

    const userRef = doc(db, "users", pan);
    const userSnap = await getDoc(userRef);
  

    if (!userSnap.exists()) {
      console.error("User document not found");
      return;
    }
    const userData = userSnap.data();
    const { name, policeStation, mobile } = userData;

    const currentYear = process.env.REACT_APP_CURRENT_YEAR;
    const docRef = doc(db, currentYear.toString(), pan);
 

    const Snap = await getDoc(docRef);
  

    if (!Snap.exists()) {
      console.error("User document not found");
      return;
    }
    const Data = Snap.data();
    const { services, discount, PaidFees, actualFees, Fees, invNo} = Data;
    const totalPayable = Fees - PaidFees;

    const existingPdfBytes = await fetch("../assets/Invoice Format Blank.pdf").then((res) => res.arrayBuffer());

    // Load the existing PDF as a PDFDocument
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Get the first page of the PDF
    const page = pdfDoc.getPages()[0];


    const priceMap = {
      itrBusiness: 6000,
      itrNil: 0,
      itrSalary: 2500,
      portalFee: 299,
      sharesBelow10L: 2000,
      sharesBelow20L: 3000,
      sharesBelow5L: 1000,
    };

    const renamedPriceMap = {
      itrBusiness : "Income Tax Return - Business",
      itrNil : "Income Tax Return - Nil",
      itrSalary : "Income Tax Return - Salary",
      portalFee : "Portal Fee",
      sharesBelow10L : "Shares Below 10 Lakhs",
      sharesBelow20L : "Shares Below 20 Lakhs",
      sharesBelow5L : "Shares Below 5 Lakhs",
    };
    
   
    page.drawText(`${name}`, {
      x: 10,
      y: page.getHeight() - 142,
      size: 12,
      color: rgb(0, 0, 0), 
    });

    page.drawText(`${policeStation}`, {
      x: 10,
      y: page.getHeight() - 175,
      size: 12,
      color: rgb(0, 0, 0), 
    });

    page.drawText(`${mobile}`, {
      x: 60,
      y: page.getHeight() - 207,
      size: 12,
      color: rgb(0, 0, 0), 
    });

    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      const { name } = service;

      // Check if the service name is present in the price map
      if (Object.prototype.hasOwnProperty.call(priceMap, name)) {
        const price = priceMap[name];
        const serviceNames  = renamedPriceMap[name];
        // Draw the service name and price on the PDF
        page.drawText(`${serviceNames} `, {
          x: 20,
          y: page.getHeight() - (270 + i * 30), 
          size: 12,
          color: rgb(0, 0, 0), // Black color
        });

        page.drawText(`Rs. ${price}`, {
          x: 400,
          y: page.getHeight() - (270 + i * 30),
          size: 12,
          color: rgb(0, 0, 0), 
        });

        page.drawText(`Rs. ${price}`, {
          x: 500,
          y: page.getHeight() - (270 + i * 30),
          size: 12,
          color: rgb(0, 0, 0), // Black color
        });
      }
      page.drawText("01", {
        x: 300,
        y: page.getHeight() - (270 + i * 30),
        size: 12,
        color: rgb(0, 0, 0), // Black color
      });
    }

    page.drawText(` INV${invNo.toString().padStart(2, "0")}`, { 
      x: 375,
      y: page.getHeight() - 147,
      size: 12,
      color: rgb(0, 0, 0), // Black color
    });

    page.drawText(`${getFormattedDate()}`, {
      x: 355,
      y: page.getHeight() - 175,
      size: 12,
      color: rgb(0, 0, 0), // Black color
    });


    page.drawText(`Rs. ${discount} /-`, { 
      x: 500,
      y: page.getHeight() - 470,
      size: 12,
      color: rgb(0, 0, 0), // Black color
    });


    page.drawText(`Rs. ${actualFees} /-`, { 
      x: 500,
      y: page.getHeight() - 440,
      size: 12,
      color: rgb(0, 0, 0), // Black color
    });


    page.drawText(`Rs. ${PaidFees} /-`, { // Use the provided invoice number
      x: 500,
      y: page.getHeight() - 500,
      size: 12,
      color: rgb(0, 0, 0), // Black color
    });

    

    page.drawText(`Rs. ${totalPayable}/-`, { // Use the provided invoice number
      x: 500,
      y: page.getHeight() - 530,
      size: 12,
      color: rgb(1, 1, 1), // white color
    });

    page.drawText(`INR ${numberToWords(Fees)} Only`, {
      x: 260,
      y: page.getHeight() - 555,
      size: 11,
      color: rgb(0, 0, 0), // Black color
    });
    // Save the modified PDF as a Uint8Array
    const modifiedPdfBytes = await pdfDoc.save();

    // Create a Blob from the Uint8Array
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "invoice.pdf";
    a.style.display = "none";

    // Append the anchor element to the document body and click it programmatically
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor element and revoking the URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error modifying and downloading PDF:", error);
  }
};


