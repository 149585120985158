import { React } from "react";
import Upload from "./components/fileUpload";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard";
import UploadITR from "./components/uploadITR";
import User from "./components/user";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import Profile from "./components/profile";
import SignIn from "./components/singin";
import { Landing } from "./components/landing";
import NewUser from "./components/addUser2";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/upload" element={<Upload />} />
          <Route exact path="/addUser" element={<NewUser /> }/>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/punchedUsers" element={<UploadITR />} />
          <Route exact path="/user/:userId" element={<User />} />
          <Route path="profile" element={<Profile />} />
          <Route path="login" element={<SignIn />} />
          <Route path="/" element={<Landing />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
