import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "fir-9-82868.firebaseapp.com",
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: "fir-9-82868",
  storageBucket: "fir-9-82868.appspot.com",
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// connectAuthEmulator(auth, "http://localhost:9090/dummy-admin/");
// connectFirestoreEmulator(db, "127.0.0.1", 8080);
// connectStorageEmulator(storage, "127.0.0.1", 8080);
// connectFunctionsEmulator(functions, "127.0.0.1", 8080);

// auth.useEmulator("http://localhost:9090");
// db.useEmulator("localhost", 8080);  // Corrected line
// storage.useEmulator("localhost", 9199);
// functions.useEmulator("localhost", 5001);

export {auth, db, storage, functions};