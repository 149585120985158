import {
  collection,
  query,
  onSnapshot,
  where,
  startAfter,
  // limit,
  getDocs,
  orderBy,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "../firebase";

export const getQueries = async (isAdmin, emailId) => {
  const docRef = query(collection(db, "queries"), orderBy("status"));
  const querySnapshot = await getDocs(docRef);
  const data  = querySnapshot.docs.map((doc) => doc.data());
  return data;
}

