import React, { useState } from "react";
import "./queryList.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const Queries = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [feedback, setFeedback] = useState(props.feedback);
  const [resolvedBy, setResolvedBy] = useState("visit");
  const [status, setStatus] = useState(props.status);
  const [pan, setPan] = useState(props.pan);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const resolveQuery = async (PANId, resolution, resolvedBy, status) => {
    const docRef = doc(db, "queries", PANId);
    try {
      await updateDoc(docRef, {
        resolution: resolution,
        resolvedBy: resolvedBy,
        status: status,
      });

    } catch (error) {
      alert("Error in resolution");
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await resolveQuery(pan, feedback, resolvedBy, newStatus);
  };

  let statusColor = "";
  switch (status) {
  case "pending":
    statusColor = "red";
    break;
  case "underprocess":
    statusColor = "yellow";
    break;
  case "resolved":
    statusColor = "green";
    break;
  default:
    statusColor = "";
  }

  return (
    <div className={`query-item ${statusColor}`}>
      <div className="query_basic row">
        <div className="query__client_name col-md-3">Client: {props.clientName}</div>
        <div className="query__details col-md-6 row">
          <div className="query__details_item col-md-4">{props.pan}</div>
          <div className="query__details_item col-md-4">{props.phone}</div>
          <div className="query__details_item col-md-4">{props.userType.slice(0, 10)}</div>
        </div>
        <button className="show_more_btn col-md-1" onClick={toggleDetails}>
          {showDetails ? "Hide" : "Show"}
        </button>
      </div>

      {showDetails && (
        <>
          <div className="queryInteractions">{props.query}</div>
          <div className="query_base">
            <input
              className="query_feedback"
              placeholder="Feedback by Team"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            Contacted
            <select name="resolvedby" onChange={(e) => setResolvedBy(e.target.value)}>
              <option value="visit">Visit</option>
              <option value="call">On call</option>
              <option value="whatsapp">Whatsapp</option>
              {/*  {props.raisedBy} */}
            </select>
          </div>
          <div className="query_base">
            <div className="query_raisedon">Raised On: {props.raisedOn}</div>
            <select value={status} onChange={handleStatusChange}>
              <option value="pending">Pending</option>
              <option value="underprocess">Under Process</option>
              <option value="resolved">Resolved</option>
            </select>
          </div>
        </>
      )}
    </div>
  );
};
