import React, { useEffect, useState } from "react";
import "./queries.css";
import { Queries } from "./queryList";
import { getQueries } from "../services/queries";

export const Query = (props) => {
  console.log("queries props are..", props);

  const [queries, setQueries] = useState({});
  const [makeRequest, setMakeRequest] = useState(true);

  useEffect(() => {
    makeRequest && fetchQueries();
  }, [makeRequest]);

  const fetchQueries = async () => {
    try {
      const value = await getQueries();
      const formattedQueries = value.map((e) => ({
        ...e,
        raisedOn: convertTime(e.raisedOn["seconds"]),
      }));
      setQueries(formattedQueries);
      console.log(formattedQueries);
    } catch (error) {
      console.log(error);
    }
  };

  const convertTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <div
      className="mainBackground"
      onClick={(e) =>
        e.target.getAttribute("id") == "queryBackground"
          ? props.closeQueryWindow()
          : ""
      }
    >
      <div className="queryBackground" id="queryBackground">
        <div className="query-section">
          <div className="section__title">
            <h2 className="query-section__title">QUERIES</h2>
          </div>
          <div className="query__textarea">
            {Object.keys(queries).map((query) => (
              <Queries
                key={query} // Adding a unique key prop when mapping over arrays in React
                clientName={queries[query].clientName}
                pan={queries[query].pan}
                phone={queries[query].phone}
                userType={queries[query].userType}
                query={queries[query].query}
                raisedOn={queries[query].raisedOn}
                status={queries[query].status}
                feedback={queries[query].resolution}
              />
            ))}
          </div>
          <button className="query_add" disabled>
            Add Query
          </button>
        </div>
      </div>
    </div>
  );
};
