import React from "react";
import { useState, useEffect } from "react";
import { YearSelector } from "./blocks/yearSelector";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import "./dash.css";
import Modal from "react-modal";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Uploadimg from "../assets/images/img.png";
import Downloadimg from "../assets/images/download.png";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { TopNav } from "./blocks/topNav";
Modal.setAppElement("#root");

const UploadITR = () => {
  const [year, setYear] = useState(process.env.REACT_APP_CURRENT_YEAR);
  const [userName, setUserName] = useState("User Name");
  const [pan, setPan] = useState();
  const [tasks, setTasks] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedUserUid, setSelectedUserUid] = useState(null);
  const [selectedUserPan, setSelectedUserPan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [isSearched, setIsSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [FilteredTasks, setFilteredTasks] = useState([]);
  const navigate = useNavigate();
  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS.split(",");
  const salesEmails = process.env.REACT_APP_SALES_EMAILS.split(",");


  const [userType, setUserType] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [makeRequest, setMakeRequest] = useState(false);

  useEffect(() => {
    // const unsubscribe = onAuthStateChanged(auth, async (user) => {
    //   if (!user) {
    //     return navigate("/login");
    //   }
    //   const userEmail = user.email;
    //   const isAdmin = adminEmails.includes(userEmail);
    //   if (isAdmin) {
    //     setUser(user);
    //     setUserName(user.displayName);
    //   } else {
    //     auth
    //       .signOut()
    //       .then(() => {
    //         alert(
    //           "You are not authorized to access this page. Please contact the administrator."
    //         );
    //         return navigate("/login", { replace: true });
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // });
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        return navigate("/login");
      }
      const userEmail = user.email;
      setUserName(pan);
      const AdminId = adminEmails.includes(userEmail);
      const isSales = salesEmails.includes(userEmail);
      // const isEmployee = employeeEmails.includes(userEmail);
      if (AdminId || isSales) {
        setUserName(user.displayName);
        setUser(user);
        if(AdminId) {
          setUserType("ADMIN");
          setIsAdmin(true);
        } else {
          setUserType("SALES");
          setIsSales(true);
        }
        setMakeRequest(true);
      } 
      else {
        auth
          .signOut()
          .then(() => {
            alert(
              "Upload You are not authorized to access this page. Please contact the administrator."
            );
            return navigate("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    return () => unsubscribe;
  }, [navigate]);

  useEffect(() => {
    const filterTasks = () => {
      const query = searchQuery.toLowerCase();
      const filtered = tasks.filter(
        (task) =>
          task.name.toLowerCase().includes(query) ||
          task.pan.toLowerCase().includes(query) ||
          task.mobile.includes(query)
      );
      setFilteredTasks(filtered);
    };

    filterTasks();
  }, [searchQuery, tasks]);

  useEffect(() => {
    if (selectedUserUid) {
      console.log("use effect selectedUserUid is ", selectedUserUid);
      togglePopup();
    }
  }, [selectedUserUid]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log("selected file is", selectedFile);
  };

  const handleUserSelectionAndDownload = async (pan) => {
    console.log("download uid is ", pan);
    const currentYear = process.env.REACT_APP_CURRENT_YEAR;
    const questRef = doc(db, currentYear.toString(), pan);
    const questSnap = await getDoc(questRef);
    if (questSnap.exists()) {
      if (questSnap.data()["path"] === -1) {
        alert("No doument avaliable for download");
      } else {
        window.open(questSnap.data()["path"], "_blank");
      }
    }
  };

  const handleUserSelection = (pan) => {
    console.log("handle selection uid is", pan);
    setSelectedUserPan(pan);   
    togglePopup();
  };

  const handleFileUpload = async () => {
    console.log("upload button clicked");
    console.log("file selected on upload is", selectedFile);
    console.log("user id is", selectedUserPan);
    if (selectedFile && selectedUserPan) {
      setIsLoading(true);
      const fileName = `${selectedUserPan}_ITRAcknowledgement`;
      // const storageRef = ref(
      //   storage,
      //   `ITRFolder/AY-${year}/` + fileName
      // );
      const storageRef = ref(
        storage,
        `ITRAcknowledgement/AY-${year}/` + fileName
      );
      try {
        await uploadBytes(storageRef, selectedFile);
        const fileUrl = await getDownloadURL(storageRef);

        const docRef = doc(db, year, selectedUserPan);
        const obj = {
          path: fileUrl,
        };
        await updateDoc(docRef, obj);
        alert("Details Updated Successfully!");
        window.location.reload(false);
        setIsLoading(false);
        togglePopup();
      } catch (error) {
        console.error("Error uploading file: ", error);
        alert("Error uploading file: ", error);
      }
    }
  };

  const fetch = async () => {
    setIsSearched(false);
    const q = query(
      collection(db, year),
      where("punch", "==", true),
      where("path", "==", -1)
    );
    const querySnapshot = await getDocs(q);

    const userIds = querySnapshot.docs.map((doc) => doc.id);

    const usersPromises = userIds.map(async (userId) => {
      const userRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userRef);
      return userSnapshot.data();
    });

    const usersData = await Promise.all(usersPromises);
    setTasks(usersData);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className="p-3">
      <TopNav userName={userName} />
      <div className="year-selector">
        <div className="year-box">
          <h3>Upload Data for</h3>
          <YearSelector year={year} setYear={setYear} />
        </div>
      </div>

      <div className="subnav row">
        <div className="col-3 d-flex">
          <input
            name="pan"
            type="text"
            pattern="[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="form-control"
            placeholder="search Name, Pan, Mobile"
            required
          />
          <button className="btn btn-outline-primary ms-2" onClick={fetch}>
            FetchAll
          </button>
        </div>
      </div>
      <br />
      <div className="table-holder">
        <table className="table">
          <thead>
            <tr>
              <th>SR NO</th>&nbsp;
              <th>NAME</th>&nbsp;
              <th>EMAIL</th>&nbsp;
              <th>PAN</th>&nbsp;
              <th>MOBILE</th>
            </tr>
          </thead>
          <div style={{ height: "10px" }}></div>
          <tbody>
            {FilteredTasks.map((data, index) => {
              {console.log("data......", data);}
              return (
                <>
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>&nbsp;
                    <td>{data.name}</td>&nbsp;
                    <td>{data.email}</td>&nbsp;
                    <td style={{ textAlign: "center" }}>{data.pan}</td>&nbsp;
                    <td style={{ textAlign: "center" }}>{data.mobile}</td>&nbsp;
                    {isSearched && (
                      <button
                        className="custom-button"
                        onClick={() => handleUserSelectionAndDownload(data.pan)}
                      >
                        <img src={Downloadimg} alt="" className="button-image" />
                      </button>
                    )}
                    <button
                      className="custom-button"
                      onClick={() => handleUserSelection(data.pan)}
                    >
                      <img src={Uploadimg} alt="" className="button-image" />
                      Upload
                    </button>
                  </tr>
                  <div style={{ height: "10px" }}></div>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={togglePopup}
        contentLabel="Upload Popup"
        style={{
          content: {
            width: "60%",
            margin: "auto",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="modal-content">
          <h2 className="modal-title">Upload File</h2>&nbsp;
          <div className="modal-body">
            <input
              type="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <button
              className="upload-button"
              onClick={handleFileUpload}
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>
            <button className="cancel-button" onClick={togglePopup}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <br />
    </div>
  );
};

export default UploadITR;
