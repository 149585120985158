import React, { useEffect, useState } from "react";
import "./remark.css";
import {updateRemark} from "../services/remark";

export const Remark = (props) => {



  const [remark, setRemark] = useState(props.remark);
  const [userPAN] = useState(props.currentUser);

  const updateRemarkValue = (event) => {
    setRemark(event.target.value);
  }

  return (
    <div className="mainBackground" onClick={(e) => e.target.getAttribute("id") == "remarkBackground"?props.closeRemark(false):""}>
      <div className="remarkBackground" id="remarkBackground">
        <div className="remark-section">
          <div className="section__title"><h2 className="remark-section__title">REMARKS</h2></div>
          {/* <div className="close_remark"><i className="fa-solid fa-circle-xmark">X</i></div> */}
          <textarea className="remark__textarea" name="remark-value" id="remark-value" cols="100" value={remark} onChange={updateRemarkValue} rows="10" placeholder="Enter any remark if needed."></textarea>
          <button className="remark_add" onClick={() => {updateRemark(userPAN, remark); props.reloadPage();}}>SAVE</button>
        </div>
      </div>
    </div>
  );
}; 