export const analyseRes = (res) => {
  if (res) {
    const calc24 = () => {
      return (res?.recRent[0] * 12 - res?.tax[0]) * 0.3;
    };
    const calc80C = () => {
      let total = 0;
      for (let i = 0; i < 4; i++) {
        total += +res?.$80c?.[i];
      }
      return total < 150000 ? total : 150000;
    };
    const calc80D = () => {
      let total = 0;
      if (res?.$80d[0]) {
        total += res?.$80d[0] < 25000 ? +res?.$80d[0] : 25000;
      }
      if (res?.$80d[1]) {
        total += res?.$80d[1] < 50000 ? +res?.$80d[1] : 50000;
      }
      return total;
    };
    const calc80DD = () => {
      if (+res?.$80dd === 0) {
        return 0;
      } else if (+res?.$80dd === 1) {
        return 75000;
      } else if (+res?.$80dd === 2) {
        return 125000;
      }
    };
    const calc80DDB = () => {
      let total = 0;
      if (res?.$80ddb[0]) {
        total += res?.$80ddb[0] < 40000 ? +res?.$80ddb[0] : 40000;
      }
      if (res?.$80ddb[1]) {
        total += res?.$80ddb[1] < 100000 ? +res?.$80ddb[1] : 100000;
      }
      return total;
    };
    const calc80GG = () => {
      return +res?.$80gg[0] < 60000 ? +res?.$80gg[0] : 60000;
    };
    const calcInterest = () => {
      let total = 0;
      for (let i = 0; i < 3; i++) {
        total += +res?.interest?.[i];
      }
      return total;
    };
    const data = {
      incomeData: [
        {
          valid: res?.gross[0] ? true : false,
          name: "Total Salary",
          amt: Number(res?.gross[0]),
          id: 0,
        },
        {
          valid: res?.recRent[0] ? true : false,
          name: "Rent Income",
          amt: Number(res?.recRent[0] * 12),
          id: 1,
        },
        {
          valid: res?.interest[0] ? true : false,
          name: "Interest Income",
          amt: calcInterest(),
          id: 2,
        },
      ],
      allowancesData: [
        {
          askUser: true,
          valid: res?.costTourAllow ? true : false,
          name: "Cost Allowance",
          amt: Number(res?.costTourAllow),
          id: 0,
        },
        {
          // askUser: true,
          valid: res?.childEduAllow ? true : false,
          name: "Children Education Allowance",
          amt: Number(res?.childEduAllow),
          id: 1,
        },
        {
          askUser: true,
          valid: res?.rentAllow ? true : false,
          name: "Rent Allowance",
          amt: Number(res?.rentAllow),
          id: 2,
        },
        {
          askUser: true,
          valid: res?.TravelAllow ? true : false,
          name: "Travel Allowance",
          amt: Number(res?.TravelAllow),
          id: 3,
        },
        {
          askUser: true,
          valid: res?.otherAllow ? true : false,
          name: "Other Allowances",
          amt: Number(res?.otherAllow),
          id: 4,
        }
      ],
      deductionData: [
        {
          askUser: false,
          valid: true,
          name: "Standard Deduction",
          amt: 50000,
          id: 0,
        },
        {
          askUser: false,
          valid: true,
          name: "Professional Fees",
          amt: 2500,
          id: 1,
        },
        {
          askUser: false,
          valid: res?.tax[0] ? true : false,
          name: "Municipal Tax",
          amt: Number(res?.tax[0]),
          id: 2,
        },
        {
          askUser: false,
          valid: res?.recRent[0] ? true : false,
          name: "Rent Deduction",
          amt: calc24(),
          id: 3,
        },
        {
          askUser: true,
          valid: calc80C() ? true : false,
          name: "Loans, Premiums and Tution Fees",
          amt: calc80C(),
          id: 4,
          max: 150000,
        },
        {
          askUser: true,
          valid: res?.nps[0] ? true : false,
          name: "National Pension Scheme",
          amt: 5 * 10000,
          id: 5,
          max: 50000,
        },
        {
          askUser: true,
          valid: calc80D() ? true : false,
          name: "Health Insurance Premium",
          amt: calc80D(),
          id: 6,
          max: 75000,
        },
        {
          askUser: true,
          // eslint-disable-next-line no-extra-boolean-cast
          valid: !!+res?.$80dd ? true : false,
          name: "Disability Treatment",
          amt: calc80DD(),
          id: 7,
          max: 125000,
        },
        {
          askUser: true,
          valid: calc80DDB() ? true : false,
          name: "Medical Treatment",
          amt: calc80DDB(),
          id: 8,
          max: 140000,
        },
        {
          askUser: true,
          valid: res?.$80g[0] ? true : false,
          name: "Donations",
          amt: res?.$80g[0],
          id: 9,
        },
        {
          askUser: true,
          valid: res?.$80gg[0] ? true : false,
          name: "Rent Paid",
          amt: calc80GG(),
          id: 10,
        },
      ],
      suggestions: res?.suggestions,
    };
    return data;
  } else {
    const data = {
      incomeData: [
        {
          valid: false,
          name: "Total Salary",
          amt: 0,
          id: 0,
        },
        {
          valid: false,
          name: "Rent Income",
          amt: 0,
          id: 1,
        },
        {
          valid: false,
          name: "Interest Income",
          amt: 0,
          id: 2,
        },
      ],

      allowancesData: [
        {
          askUser: true,
          valid: res?.costTourAllow ? true : false,
          name: "Cost Allowance",
          amt: Number(res?.costTourAllow),
          id: 0,
        },
        {
          // askUser: true,
          valid: res?.childEduAllow ? true : false,
          name: "Children Education Allowance",
          amt: Number(res?.childEduAllow),
          id: 1,
        },
        {
          askUser: true,
          valid: res?.rentAllow ? true : false,
          name: "Rent Allowance",
          amt: Number(res?.rentAllow),
          id: 2,
        },
        {
          askUser: true,
          valid: res?.TravelAllow ? true : false,
          name: "Travel Allowance",
          amt: Number(res?.TravelAllow),
          id: 3,
        },
        {
          askUser: true,
          valid: res?.otherAllow ? true : false,
          name: "Other Allowances",
          amt: Number(res?.otherAllow),
          id: 4,
        }
      ],
      deductionData: [
        {
          askUser: false,
          valid: true,
          name: "Standard Deduction",
          amt: 50000,
          id: 0,
        },
        {
          askUser: false,
          valid: true,
          name: "Professional Fees",
          amt: 2500,
          id: 1,
        },
        {
          askUser: true,
          valid: false,
          name: "Municipal Tax Paid",
          amt: 0,
          id: 2,
        },
        {
          askUser: true,
          valid: false,
          name: "24",
          amt: 0,
          id: 3,
        },
        { askUser: true, valid: false, name: "80C", amt: 0, id: 4 },
        {
          askUser: true,
          valid: false,
          name: "80CCD(1B)",
          amt: 0,
          id: 5,
        },
        { askUser: true, valid: false, name: "80D", amt: 0, id: 6 },
        {
          askUser: true,
          valid: false,
          name: "80DD(1B)",
          amt: 0,
          id: 7,
        },
        {
          askUser: true,
          valid: false,
          name: "80DDB",
          amt: 0,
          id: 8,
        },
        { askUser: true, valid: false, name: "80G", amt: res?.$80g, id: 9 },
        {
          askUser: true,
          valid: false,
          name: "80GG",
          amt: 0,
          id: 10,
        },
      ],
      suggestions: [
        false /* 0 */,
        false /* 1 */,
        false /* 2 */,
        false /* 3 */,
        true /* 4 */,
        true /* 5 */,
        true /* 6 */,
        true /* 7 */,
        true /* 8 */,
        true /* 9 */,
        true /* 10 */,
      ],
    };

    return data;
  }
};
