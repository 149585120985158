import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const Library = () => {
  const { t } = useTranslation();
  const data = {
    questions: [
      [
        "What is your Annual Gross Salary for the Current Financial year?",
        "आपकी इस आर्थिक वर्ष की ग्रॉस सैलरी कितनी है?",
      ],
      [
        "Do you receive any Rent ? If yes, how much Monthly rent do you Receive?",
        "क्या आपको कही से रेंट आता है? अगर है, तो प्रति माह कितना आता?",
      ],
      [
        "What is the Amount of Municipal Tax Paid by you in the current Financial Year?",
        "आपके प्रॉपर्टी की इस आर्थिक वर्ष का म्युनिसिपल टैक्स कितना था?",
      ],
      [
        "Do you have any Interest from FDs, RDs, Or any other Investments ? If yes, what is the Total Interest Reveived?",
        "आपको इस आर्थिक वर्ष मे कोई इंटरेस्ट मिला है, जैसे FD का इंटरेस्ट या RD का इंटरेस्ट ?",
      ],
      [
        "Do you have an LIC Policy, Home Loan, GPF, Tutuion Fees? What is the total Premium you pay?",
        "क्या आपकी कोई LIC POLICY, Home Loan, GPF, Tutuion Fees है? प्रीमियम अमाउंट बताये?",
      ],
      [
        "If you contribute to National Pension Scheme, write down annual amount?",
        "अगर आप, NATIONAL PENSION SCHEME मे अपना योगदान देते हो तो उसकी वार्षिक धनराशि बताये?",
      ],
      [
        "If you pay to HEALTH INSURANCE PREMIUM, write down annual Amount?",
        "अगर आपका  HEALTH INSURANCE PREMIUM हो तो उसकी वार्षिक धनराशि बताये?",
      ],
      ["Do you any permanent disability?", "क्या आपको कोई विकलांगता है?"],
      [
        "Did you spend any money on your own or your family's medical treatment? If yes then how much",
        "क्या आपको अपने या अपने परिवार के चिकित्सा व्यवस्था के लिए कोई खर्च हुआ? अगर है तो कितना?",
      ],
      [
        "Did you donate money to any charity? If yes then how much",
        "क्या आपने किसी अन्य व्यक्ति को धन दान किया? अगर है तो कितना?",
      ],
      [
        "Do you pay rent? If yes then how much?",
        "क्या आपको रेंट देना पड़ता है? अगर है तो कितना?",
      ],
    ],
  };

  return data;
};

export const responseSchema = {
  gross: [0],
  interest: [0, 0, 0],
  recRent: [0],
  tax: [0],
  nps: [0],
  $80c: [0, 0, 0, 0],
  $80d: [0, 0],
  $80ddb: [0, 0],
  $80g: [0],
  $80gg: [0],
  $80dd: [0],
  suggestions: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
  },
};

export const analyseRes = (res) => {
  if (res) {
    const calc24 = () => {
      return (res?.recRent[0] * 12 - res?.tax[0]) * 0.3;
    };
    const calc80C = () => {
      let total = 0;
      for (let i = 0; i < 4; i++) {
        total += +res?.$80c?.[i];
      }
      return total < 150000 ? total : 150000;
    };
    const calc80D = () => {
      let total = 0;
      if (res?.$80d[0]) {
        total += res?.$80d[0] < 25000 ? +res?.$80d[0] : 25000;
      }
      if (res?.$80d[1]) {
        total += res?.$80d[1] < 50000 ? +res?.$80d[1] : 50000;
      }
      return total;
    };
    const calc80DD = () => {
      if (+res?.$80dd === 0) {
        return 0;
      } else if (+res?.$80dd === 1) {
        return 75000;
      } else if (+res?.$80dd === 2) {
        return 125000;
      }
    };
    const calc80DDB = () => {
      let total = 0;
      if (res?.$80ddb[0]) {
        total += res?.$80ddb[0] < 40000 ? +res?.$80ddb[0] : 40000;
      }
      if (res?.$80ddb[1]) {
        total += res?.$80ddb[1] < 100000 ? +res?.$80ddb[1] : 100000;
      }
      return total;
    };
    const calc80GG = () => {
      return +res?.$80gg[0] < 60000 ? +res?.$80gg[0] : 60000;
    };
    const calcInterest = () => {
      let total = 0;
      for (let i = 0; i < 3; i++) {
        total += +res?.interest?.[i];
      }
      return total;
    };
    const data = {
      incomeData: [
        {
          valid: res?.gross[0] ? true : false,
          name: "Total Salary",
          amt: Number(res?.gross[0]),
          id: 0,
        },
        {
          valid: res?.recRent[0] ? true : false,
          name: "Rent Income",
          amt: Number(res?.recRent[0] * 12),
          id: 1,
        },
        {
          valid: res?.interest[0] ? true : false,
          name: "Interest Income",
          // amt: Number(res?.interest[0]),
          amt: calcInterest(),
          id: 2,
        },
      ],

      deductionData: [
        {
          askUser: false,
          valid: true,
          name: "Standard Deduction",
          amt: 50000,
          id: 0,
        },
        {
          askUser: false,
          valid: true,
          name: "Professional Fees",
          amt: 2500,
          id: 1,
        },
        {
          askUser: false,
          valid: res?.tax[0] ? true : false,
          name: "Municipal Tax",
          amt: Number(res?.tax[0]),
          id: 2,
        },
        {
          askUser: false,
          valid: res?.recRent[0] ? true : false,
          name: "Rent Deduction",
          amt: calc24(),
          id: 3,
        },
        {
          askUser: true,
          valid: calc80C() ? true : false,
          name : t("Loans, Premiums and Tution Fees"),
          amt: calc80C(),
          id: 4,
          max: 150000,
        },
        {
          askUser: true,
          valid: res?.nps[0] ? true : false,
          name: t("National Pension Scheme"),
          amt: 5 * 10000,
          id: 5,
          max: 50000,
        },
        {
          askUser: true,
          valid: calc80D() ? true : false,
          name: "Health Insurance Premium",
          amt: calc80D(),
          id: 6,
          max: 75000,
        },
        {
          askUser: true,
          // eslint-disable-next-line no-extra-boolean-cast
          valid: !!+res?.$80dd ? true : false,
          name: "Disability Treatment",
          amt: calc80DD(),
          id: 7,
          max: 125000,
        },
        {
          askUser: true,
          valid: calc80DDB() ? true : false,
          name: "Medical Treatment",
          amt: calc80DDB(),
          id: 8,
          max: 140000,
        },
        {
          askUser: true,
          valid: res?.$80g[0] ? true : false,
          name: "Donations",
          amt: res?.$80g[0],
          id: 9,
        },
        {
          askUser: true,
          valid: res?.$80gg[0] ? true : false,
          name: "Rent Paid",
          amt: calc80GG(),
          id: 10,
        },
      ],
      suggestions: res?.suggestions,
    };

    return data;
  } else {
    const data = {
      incomeData: [
        {
          valid: false,
          name: "Total Salary",
          amt: 0,
          id: 0,
        },
        {
          valid: false,
          name: "Rent Income",
          amt: 0,
          id: 1,
        },
        {
          valid: false,
          name: "Interest Income",
          amt: 0,
          id: 2,
        },
      ],

      deductionData: [
        {
          askUser: false,
          valid: true,
          name: "Standard Deduction",
          amt: 50000,
          id: 0,
        },
        {
          askUser: false,
          valid: true,
          name: "Professional Fees",
          amt: 2500,
          id: 1,
        },
        {
          askUser: true,
          valid: false,
          name: "Municipal Tax Paid",
          amt: 0,
          id: 2,
        },
        {
          askUser: true,
          valid: false,
          name: "24",
          amt: 0,
          id: 3,
        },
        { askUser: true, valid: false, name: "80C", amt: 0, id: 4 },
        {
          askUser: true,
          valid: false,
          name: "80CCD(1B)",
          amt: 0,
          id: 5,
        },
        { askUser: true, valid: false, name: "80D", amt: 0, id: 6 },
        {
          askUser: true,
          valid: false,
          name: "80DD(1B)",
          amt: 0,
          id: 7,
        },
        {
          askUser: true,
          valid: false,
          name: "80DDB",
          amt: 0,
          id: 8,
        },
        { askUser: true, valid: false, name: "80G", amt: res?.$80g, id: 9 },
        {
          askUser: true,
          valid: false,
          name: "80GG",
          amt: 0,
          id: 10,
        },
      ],
      suggestions: [
        false /* 0 */,
        false /* 1 */,
        false /* 2 */,
        false /* 3 */,
        true /* 4 */,
        true /* 5 */,
        true /* 6 */,
        true /* 7 */,
        true /* 8 */,
        true /* 9 */,
        true /* 10 */,
      ],
    };

    return data;
  }
};

export const TotalTaxableIncome = (I, D) => {
  let totalIncome = 0;
  I.forEach((item) => {
    if (item.valid) totalIncome += item.amt;
  });
  let totalDeduction = 0;
  D.forEach((item) => {
    if (item.valid) totalDeduction += +item.amt;
  });
  let taxableIncome = totalIncome - totalDeduction;
  // console.log(totalIncome, totalDeduction);
  // console.log(taxableIncome);
  // console.log(taxableIncome);
  if (taxableIncome < 0) {
    // eslint-disable-next-line no-useless-concat
    return 0 + " " + "Income less than Deductions";
  } else if (taxableIncome >= 0 && taxableIncome < 500001) {
    return 0;
  } else if (taxableIncome >= 500001 && taxableIncome < 1000000) {
    return (
      12500 +
      0.2 * (taxableIncome - 500000) +
      (12500 + 0.2 * (taxableIncome - 500000)) * 0.04
    ).toFixed(2);
  } else if (taxableIncome >= 1000001) {
    return (
      112500 +
      0.3 * (taxableIncome - 1000000) +
      (112500 + 0.3 * (taxableIncome - 1000000) * 0.04)
    ).toFixed(2);
  }
};

export const TotalTaxPaid = (tax) => {
  let sum = 0;
  if (tax) {
    for (let i = 0; i < tax.length; i++) {
      sum = sum + tax[i].jan + tax[i].feb + tax[i].mar;
    }
  }
  return sum;
};

// userData: {
//   name: fData.name,
//   email: fData.email,
//   mobile: fData.phone,
//   pan: fData.pan,
//   question: fData.question,
//   role: fData.role,
//   accountNumber: 6666666666,
//   IFSC: "SBIN0000000",
//   aadhar: 666666666666,
//   pastITR: [
//     { year: "2020 - 21", link: "https://www.google.com" },
//     { year: "2021 - 22", link: "https://www.google.com" },
//     { year: "2022 - 23", link: "https://www.google.com" },
//   ],
// },
