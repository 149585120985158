import { React, useEffect, useState } from "react";
import {
  Select, MenuItem, InputLabel, FormControl, Dialog,
  Checkbox, FormControlLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Chip
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { updateAssignValue, getAllAsigned, getAssignedUsers } from "../services/assign";
import { updateDoc, doc, getDoc, increment } from "firebase/firestore";
import { db } from "../firebase";
import "./Table.css";


import {
  modifyAndDownloadPDF,
} from "../services/invoice";
import { fetchAssignedcount } from "../services/fetch";


export const updateUserData = async (pan, updatedData) => {
  const currentYear = process.env.REACT_APP_CURRENT_YEAR;
  const docRef = doc(db, currentYear.toString(), pan);
  console.log("Updated Data:", updatedData);

  try {
    await updateDoc(docRef, updatedData);
    console.log("User data updated successfully");
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};

const Table = ({ tasks, setTasks, userType, employeeDetails, getRemarkdata, userEmail, setAssignedUsersCount }) => {

  const [userData, setUserData] = useState();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [actualFees, setActualFees] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [Fees, setFees] = useState(0);
  const [PaidFees, setPaidFees] = useState(0);
  const [PendingFees, setPendingFees] = useState(0);
  const [services, setServices] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);

  const updateTasks = (data) => {
    const updatedTasks = tasks.map((task) => {
      if (task.pan === selectedRow.pan) {
        return {
          ...task,
          Fees: data.Fees || task.Fees,
          PaidFees: data.PaidFees || task.PaidFees,
          PendingFees: data.PendingFees || task.PendingFees,
          actualFees: data.actualFees || task.actualFees,
          discount: data.discount || task.discount,
        };
      }
      return task;
    });
    setTasks(updatedTasks);
    console.log("Tasks updated:", updatedTasks);
  };

  useEffect(() => {
    const fetchAssignedUsers = async () => {
      console.log("User type:", userType);
      console.log("User email:", userEmail);

      if (userType === "OPERATION") {
        try {
          const assignedUsers = await getAssignedUsers(userEmail);
          console.log("Assigned users: ", assignedUsers);
          const filteredUsers = assignedUsers.filter((user) => user.assign === userEmail);
          setFilteredTasks(filteredUsers);
          console.log("Filtered users :", filteredUsers);
          const assignedUsersCount = await filteredUsers.length;
          // console.log("Assigned Users Count :", assignedUsersCount);
          setAssignedUsersCount(assignedUsersCount);
        } catch (error) {
          console.error("Error filtering tasks:", error);
        }
      } else {
        setFilteredTasks(tasks);
      }
    };

    fetchAssignedUsers();
  }, [tasks, userType, userEmail, getAllAsigned, setAssignedUsersCount]);



  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data...");
      if (!selectedRow || !selectedRow.pan) {
        console.log("selectedRow is null or pan is missing:", selectedRow);
        return;
      }
      try {
        console.log("selectedRow:", selectedRow);
        const userDocRef = doc(db, "users", selectedRow.pan);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        console.log("User data fetched:", userData);

        const currentYear = process.env.REACT_APP_CURRENT_YEAR;
        const feesDocRef = doc(db, currentYear.toString(), selectedRow.pan);
        const feesDocSnap = await getDoc(feesDocRef);
        const feesData = feesDocSnap.data();
        console.log("Fees data fetched:", feesData);

        if (userData && feesData) {
          const mergedData = { ...userData, ...feesData };
          console.log("Merged Data:", mergedData);
          setFees(mergedData.Fees || 0);
          setPaidFees(mergedData.PaidFees || 0);
          setPendingFees(mergedData.PendingFees || 0);
          setActualFees(mergedData.actualFees || 0);
          setDiscount(mergedData.discount || 0);
          updateTasks(mergedData);
        } else {
          console.log("User or fees data not found!");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    console.log("selectedRow in useEffect:", selectedRow);
    fetchData();
  }, [selectedRow]);


  // // useEffect to log editedData.discount when it changes
  // useEffect(() => {
  //   console.log("Discount value changed:", editedData.discount);
  // }, [editedData.discount]);

  // // Function to handle changes in the discount input field
  // const handleDiscountChange = (e) => {
  //   const discountValue = parseInt(e.target.value); // Parse discount value as integer
  //   setEditedData((prevData) => ({
  //     ...prevData,
  //     discount: discountValue, // Update discount value in editedData state
  //   }));
  // };



  // const fetchAllDetails = async () => {
  //   setUserData(getAllAsigned());
  // }
  // fetchAllDetails();
  // console.log(tasks);



  const handleEditClick = async (data) => {
    console.log("Edit button clicked with data:", data);
    setSelectedRow(data); // Set the selected row first
    setEditDialogOpen(true); // Open the edit dialog

    try {
      // Fetch additional data needed for editing from Firestore
      const currentYear = process.env.REACT_APP_CURRENT_YEAR;
      const docRef = doc(db, currentYear.toString(), data.pan);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const fetchedData = docSnap.data();
        console.log("Data fetched:", fetchedData);

        // Set the state for editedData with the fetched data
        setEditedData({
          ...data, // Keep existing data
          actualFees: fetchedData.actualFees || 0,
          Fees: fetchedData.Fees || 0,
          PaidFees: fetchedData.PaidFees || 0,
          PendingFees: fetchedData.PendingFees || 0,
          discount: fetchedData.discount || 0,
          services: fetchedData.services || [],
        });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      // Handle error
    }
  };

  const handlePaidFeesChange = async (pan, newPaidFees, prevPaidFees) => {
    try {
      // Check if the PaidFees value has changed
      if (newPaidFees !== prevPaidFees) {
        const currentYear = process.env.REACT_APP_CURRENT_YEAR;
        const userDocRef = doc(db, currentYear.toString(), pan);
        const DocRef = doc(db, "users", pan);
        const invoiceDocRef = doc(db, "invoice", "INV");

        // Get the current value of currentInvNo from the invoice collection
        const invoiceDocSnap = await getDoc(invoiceDocRef);
        const currentInvValue = invoiceDocSnap.data().currentInvNo;

        if (newPaidFees > 199) {

          const currentYear = process.env.REACT_APP_CURRENT_YEAR;
          const userDocRef = doc(db, currentYear.toString(), pan);

          const paymentData = {
            paymentRequired: false,
          }

          await Promise.all([
            updateDoc(userDocRef, paymentData),
          ]);

        }
        else {
          console.log("newpaidpess is less than 199");

          const currentYear = process.env.REACT_APP_CURRENT_YEAR;
          const userDocRef = doc(db, currentYear.toString(), pan);

          const paymentData = {
            paymentRequired: true,
          }

          await Promise.all([
            updateDoc(userDocRef, paymentData),
          ]);

        }

        // Prepare the updates for the user document and invoice collection
        const userUpdates = {
          invNo: currentInvValue,
        };

        const invoiceUpdates = {
          currentInvNo: increment(1),
        };

        // Update the user document and the invoice collection
        await Promise.all([
          updateDoc(userDocRef, userUpdates),
          updateDoc(DocRef, userUpdates),
          updateDoc(invoiceDocRef, invoiceUpdates),
        ]);

        console.log("User document and invoice collection updated successfully");
      }
      else {
        console.log("PaidFees value has not changed");
      }
    } catch (error) {
      console.error("Error updating documents:", error);
    }
  };


  const handleSaveClick = async () => {
    const updatedData = {
      actualFees: editedData.actualFees,
      discount: editedData.discount || 0,
      Fees: editedData.actualFees - editedData.discount,
      PaidFees: editedData.PaidFees,
      PendingFees: editedData.actualFees - editedData.discount - editedData.PaidFees,
      services: editedData.services,
    };
    try {
      // Use the function to update user data
      console.log("saved updated data is: ", updatedData);
      await updateUserData(selectedRow.pan, updatedData);
      await handlePaidFeesChange(selectedRow.pan, updatedData.PaidFees, selectedRow.PaidFees);
      console.log("Tasks before update:", tasks);
      // Update the local state or trigger a data refresh if needed
      const updatedTasks = tasks.map((task) =>
        task.pan === selectedRow.pan
          ? { ...task, ...updatedData, PendingFees: updatedData.PendingFees } : task);


      setTasks(updatedTasks);
      setUserData(updatedData);
      console.log("Tasks before update:", updatedTasks);
      setEditDialogOpen(false); // Close the dialog
    } catch (error) {
      console.error("Error updating user data:", error);
      // Handle the error, show a message to the user, etc.
    }
  };

  const handleCancelClick = () => {
    setEditDialogOpen(false);
  };


  const columns = [];

  const common_1 = [
    { field: "id", headerName: "sr.no.", width: 90, sortable: false, flex: 0.2 },
    { field: "name", headerName: "name", width: 120, flex: 2 },
    { field: "mobile", headerName: "Mobile", width: 150, flex: 1.5 },
    {
      field: "pan",
      headerName: "PAN",
      width: 130,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center", fontWeight: "700" }}>
            {params.value}
          </div>
        );
      },
    },
    { field: "itrType", headerName: "ITR Type", width: 100, flex: 1 },
    { field: "area", headerName: "AREA/NATURE", width: 130, flex: 1 },
    { field: "city", headerName: "city", width: 100, flex: 1 },
    userType !== "SALES" ? { field: "efilling", headerName: "E-Filling Pass", width: 100, flex: 1 } : null,

  ];
  const handleDownloadClick = (pan) => {
    modifyAndDownloadPDF(pan);
  };

  const common_2 = [
    {
      field: "status",
      headerName: "status",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const defaultStatus = params.row.lastStatus;
        const localStorageKey = `selectedStatus_${params.row.id}`; // Unique key for each row
        const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
        const [loading, setLoading] = useState(true);
  
        useEffect(() => {
          const fetchData = async () => {
            try {
              const currentYear = process.env.REACT_APP_CURRENT_YEAR;
              const docRef = doc(db, currentYear.toString(), params.row.pan);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.lastStatus) {
                  setSelectedStatus(data.lastStatus);
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
            setLoading(false);
          };
  
          fetchData();
        }, [params.row.pan, defaultStatus]);
  
        const handleStatusChange = async (event, pan) => {
          const newStatus = event.target.value;
          const currentYear = process.env.REACT_APP_CURRENT_YEAR;
          const docRef = doc(db, currentYear.toString(), pan);

  
          try {
            const statusObject = statusOptions.reduce((obj, status, index) => {
              obj[status] = index <= statusOptions.indexOf(newStatus);
              return obj;
            }, {});
      
            await updateDoc(docRef, { status: statusObject, lastStatus: newStatus });
            setSelectedStatus(newStatus);
            console.log("selected Status is", selectedStatus); 
            console.log("Status updated successfully");
          } catch (error) {
            console.error("Error updating status:", error);
          }
        };
  
        const statusOptions = [
          "Manager Assigned",
          "ITR Password Generated",
          "Data Entered",
          "Data Punched",
          "Verification",
          "ITR Filled",
          "ITR Pending",
          "Refund Processed",
        ];
  
        if (loading) {
          return <div>Loading...</div>;
        }
  
        return (
          <Select
            value={selectedStatus}
            onChange={(event) => {
              handleStatusChange(event, params.row.pan);
            }}
            style={{ width: "100%", textAlign: "center" }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      },
      flex: 2,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      renderCell: (data) => {
        const userPAN = data.row.pan; // Retrieve uid for the current row
        const remark = data.row.remark; // Retrieve remark from the current row
        const sendRemarkData = () => {
          getRemarkdata(true, remark, userPAN);
        }
        return (
          <div>
            <button className="remarks" onClick={() => handleDownloadClick(data.row.pan)}>
              <img src="assets/download.svg" alt="remark icon" />
            </button>
            <button className="remarks" onClick={() => handleEditClick(data.row)}>
              <img src="assets/edit_icon.svg" alt="remark icon" />
            </button>
            <button className="remarks" onClick={sendRemarkData}>
              <img src="assets/remark.svg" alt="remark icon" />
            </button>
            <Link to={`/user/${userPAN}`} className="view-profile-button">
              <button
                style={{
                  border: "none",
                  borderRadius: "25px",
                  background: "var(--theme-blue)",
                  fontWeight: 700,
                  padding: "10px 20px",
                  color: "var(--theme-green)",
                }}
              >
                View
              </button>
            </Link>

          </div>
        );
      },
      flex: 3,
    },];

  const assignOption = userType === "OPS" ? {} : {
    field: "assign",
    headerName: "ASSIGN",
    width: 150,
    sortable: false,
    renderCell: (data) => {
      let assignData = data.row.assign;
      const [currentAssign, setCurrentAssign] = useState(assignData);
      // const EmployeeList = process.env.REACT_APP_EMPLOYEE_EMAILS.split(",");

      const opsEmails = process.env.REACT_APP_OPS_EMAILS.split(",");
      const [updateAction, setUpdateAction] = useState(false)

      useEffect(() => {
        if (updateAction) {
          updateAssignValue(data.row.pan, currentAssign);
        }
        setUpdateAction(false);
      }, [data, currentAssign, updateAction])

      const updateAllocation = (event) => {
        setCurrentAssign(event.target.value);
        setUpdateAction(true);
      }
      return (
        <FormControl sx={{ m: 1, minWidth: 130 }}>
          <Select
            labelId="demo-simple-select-label"
            id="dummy"
            value={currentAssign}
            label="assign"
            onChange={updateAllocation}
          >
            {/* <MenuItem value={""}>{currentAssign}</MenuItem> */}
            {opsEmails.map((e) => <MenuItem key={e} value={e} selected={currentAssign === e}>{e}</MenuItem>)}
          </Select>
        </FormControl>
      )
    },
    flex: 1
  };


  const salesColumns = [
    { field: "Fees", headerName: "Fees", width: 100, flex: 1 },
    { field: "PaidFees", headerName: "Paid", width: 100, flex: 1 },
    { field: "PendingFees", headerName: "Pending", width: 130, flex: 1 },

  ];



  if (userType === "SALES" || userType === "ADMIN") {
    // Add additional columns and edit button for SALES userType
    columns.push(...common_1.filter(column => column && column.field !== "efilling"));
    columns.push(...salesColumns.filter(column => column));  // Filter out null or undefined columns
    if (Object.keys(assignOption).length > 0) {
      columns.push(assignOption);
    }
    columns.push(...common_2);
  } else {
    columns.push(...common_1.filter(column => column));
    if (userType === "ADMIN" && Object.keys(assignOption).length > 0) {
      columns.push(assignOption);
    }
    columns.push(...common_2.filter(column => column));  // Filter out null or undefined columns
  }



  //console.log("Filtered tasks", filteredTasks);

  const rows = filteredTasks.map((mergedData, index) => {
    //console.log("Rows Data:", mergedData);
    return {
      id: index + 1,
      name: mergedData.name,
      roleSet: mergedData.roleSet,
      email: mergedData.email,
      pan: mergedData.pan,
      mobile: mergedData.mobile,
      uid: mergedData.uid, // Retrieve UID from tasks data
      status: mergedData.status,
      assign: mergedData.assign,
      remark: mergedData.remark,
      city: mergedData.city,
      area: mergedData.area,
      itrType: mergedData.itrType,
      Fees: mergedData.Fees,
      PaidFees: mergedData.PaidFees,
      PendingFees: mergedData.PendingFees,
      // actualFees: mergedData.actualFees, 
      // discount: mergedData.discount,
      // services: mergedData.services
    };
  });

  const itrTypeOptions = ["ITR-1", "ITR-2", "ITR-3", "ITR-4"];

  const getRowId = (row) => {
    return row.id;
  };



  const getRowClassName = (params) => {
    const remarks = params.row.remark;
    const hasRemarks = remarks && remarks.trim() !== "";
    return hasRemarks ? "row-with-remarks" : "";
  };


  const headerClassName = () => {
    return "header-cell";
  };

  const handleCheckboxChange = (itemName, itemAmount, checked) => {
    setEditedData(prevData => {
      const updatedServices = checked
        ? [...prevData.services, { name: itemName }]  // Add the item to services array
        : prevData.services.filter(service => service.name !== itemName); // Remove the item from services array

      let updatedFees = prevData.actualFees; // Initialize updated fees with current Fees value

      // Add or subtract itemAmount based on checkbox status
      if (checked) {
        updatedFees += itemAmount;
      } else {
        updatedFees -= itemAmount;
      }

      return { ...prevData, services: updatedServices, actualFees: updatedFees };
    });
  };

  return (
    <div>
      {/* Your existing JSX remains unchanged */}

      <Dialog open={editDialogOpen} onClose={handleCancelClick}>

        <DialogTitle>Edit</DialogTitle>
        <DialogContent sx={{ display: "grid", gap: 2 }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontWeight: "bold", minWidth: "120px" }}>Name :</div>
              <div>{selectedRow && selectedRow.name}</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontWeight: "bold", minWidth: "120px" }}>PAN :</div>
              <div>{selectedRow && selectedRow.pan}</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontWeight: "bold", minWidth: "120px" }}>Mobile :</div>
              <div>{selectedRow && selectedRow.mobile}</div>
            </div>
            <div>
              <label style={{ fontWeight: "bold" }}>Services :</label>
              <label style={{ marginLeft: "50px" }}><input type="checkbox" name="portalFee" checked={editedData?.services?.some(service => service.name === "portalFee") ?? false} onChange={e => handleCheckboxChange("portalFee", 299, e.target.checked)} />Tax Sarthi Portal</label>
              <div style={{ marginLeft: "120px" }}>
                <label><input type="checkbox" name="itrNil" checked={editedData?.services?.some(service => service.name === "itrNil") ?? false} onChange={e => handleCheckboxChange("itrNil", 0, e.target.checked)} /> NIL</label>
              </div>
              <div style={{ marginLeft: "120px" }}>
                <label><input type="checkbox" name="itrSalary" checked={editedData?.services?.some(service => service.name === "itrSalary") ?? false} onChange={e => handleCheckboxChange("itrSalary", 2500, e.target.checked)} /> ITR Salary</label>
              </div>
              <div style={{ marginLeft: "120px" }}>
                <label><input type="checkbox" name="itrBusiness" checked={editedData?.services?.some(service => service.name === "itrBusiness") ?? false} onChange={e => handleCheckboxChange("itrBusiness", 6000, e.target.checked)} /> ITR Business</label>
              </div>
              <div style={{ marginLeft: "120px" }}>
                <label><input type="checkbox" name="sharesBelow5L" checked={editedData?.services?.some(service => service.name === "sharesBelow5L") ?? false} onChange={e => handleCheckboxChange("sharesBelow5L", 1000, e.target.checked)} /> Shares Below 5L</label>
              </div>
              <div style={{ marginLeft: "120px" }}>
                <label><input type="checkbox" name="sharesBelow10L" checked={editedData?.services?.some(service => service.name === "sharesBelow10L") ?? false} onChange={e => handleCheckboxChange("sharesBelow10L", 2000, e.target.checked)} /> Shares Below 10L</label>
              </div>
              <div style={{ marginLeft: "120px" }}>
                <label><input type="checkbox" name="sharesBelow20L" checked={editedData?.services?.some(service => service.name === "sharesBelow20L") ?? false} onChange={e => handleCheckboxChange("sharesBelow20L", 3000, e.target.checked)} /> Shares Below 20L</label>
              </div>
              <div>
                <div>
                  <label style={{ fontWeight: "bold" }}>Fees :</label>
                  <input style={{ marginLeft: "80px", width: "400px" }} type="number" name="ActualFees" placeholder="Enter Fees" className="input_field" value={editedData.actualFees}
                    onChange={(e) => setEditedData({ ...editedData, actualFees: e.target.value })} />
                </div>
                <div>
                  <label style={{ fontWeight: "bold" }}>Discount :</label>
                  <input style={{ marginLeft: "47px", width: "400px" }} type="number" name="Discount" placeholder="Enter Discount Amount" className="input_field" value={editedData.discount}
                    onChange={(e) => setEditedData({ ...editedData, discount: e.target.value })} />
                </div>
                <div>
                  <label style={{ fontWeight: "bold" }}>Final Fee :</label>
                  <input style={{ marginLeft: "48px", width: "400px" }} type="number" name="Fees" placeholder="Enter Fees" className="input_field" value={editedData.actualFees - editedData.discount} disabled
                    onChange={(e) => setEditedData({ ...editedData, Fees: e.target.value })} />
                </div>
                <div>
                  <label style={{ fontWeight: "bold" }}>Paid :</label>
                  <input style={{ marginLeft: "82px", width: "400px" }} type="number" name="PaidFees" placeholder="Enter Paid Fees" className="input_field"
                    value={editedData.PaidFees}
                    onChange={(e) => setEditedData({ ...editedData, PaidFees: e.target.value })} />
                </div>
                <div>
                  <label style={{ fontWeight: "bold" }}>Pending :</label>
                  <input style={{ marginLeft: "52px", width: "400px" }} type="number" name="PendingFees" placeholder="Enter Pending Fees" className="input_field" value={editedData.actualFees - editedData.discount - editedData.PaidFees}
                    onChange={(e) => setEditedData({ ...editedData, PaidFees: e.target.value })} disabled />
                </div>
                {/* <TextField
                  label="Fees"
                  type="number"
                  value={editedData.Fees}
                  onChange={(e) => setEditedData({ ...editedData, Fees: e.target.value })}
                  fullWidth  // Make the TextField full width
                  variant="outlined"  // Use the outlined variant for a rectangular appearance
                  sx={{ marginTop: 2 }}
                />
                <TextField
                  label="PaidFees"
                  type="number"
                  value={editedData.PaidFees}
                  onChange={(e) => setEditedData({ ...editedData, PaidFees: e.target.value })}
                  fullWidth
                  sx={{ marginTop: 2 }}
                />
                <TextField
                  label="PendingFees"
                  type="number"
                  value={editedData.Fees - editedData.PaidFees}
                  disabled
                  fullWidth
                  sx={{ marginTop: 2 }}
                /> */}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>Cancel</Button>
          <Button onClick={handleSaveClick}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Rest of your existing JSX */}
      <div
        style={{
          height: "60vh",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          getRowId={getRowId}
          getRowClassName={getRowClassName}
          headerClassName={headerClassName}
        />
      </div>
    </div>
  );
};

export default Table;