import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { analyseRes } from "../data/library";
import { YearSelector } from "./blocks/yearSelector";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { EmailAuthCredential, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { TopNav } from "./blocks/topNav";
import { MdContentCopy } from "react-icons/md"; // Import copy icon

const User = () => {
  const [toogle, setToogle] = useState(false);
  const [userName, setUserName] = useState("User Name");
  const [name, setName] = useState("");
  const [pan, setPan] = useState("");
  const [TAN, setTAN] = useState("");
  const [Designation, setDesignation] = useState("");
  const [efillingPass, setefillingPass] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [area, setArea] = useState("");
  const [roleSet, setRoleSet] = useState("");
  const [itrType, setItrType] = useState("");

  const [editable, setEditable] = useState(false);
  const [totalSalary, setTotalSalary] = useState(0);
  const [houseRent, setHouseRent] = useState(0);
  const [otherIncome, setOtherIncome] = useState(0);

  const [costTourAllow, setcostTourAllow] = useState(0);
  const [childEduAllow, setchildEduAllow] = useState(0);
  const [rentAllow, setrentAllow] = useState(0);
  const [TravelAllow, setTravelAllow] = useState(0);
  const [otherAllow, setotherAllow] = useState(0);


  const [deductionData, setDeductionData] = useState([]);
  const [taxPaid, setTaxPaid] = useState({});

  const [gross, setGross] = useState([""]);
  const [recRent, setRecRent] = useState([""]);
  const [tax, setTax] = useState([""]);
  const [interest, setInterest] = useState(["", "", ""]);

  const [$80c, set$80c] = useState(["", "", "", ""]);
  const [nps, setNps] = useState([""]);
  const [$80d, set$80d] = useState(["", ""]);
  const [$80dd, set$80dd] = useState("");
  const [$80ddb, set$80ddb] = useState(["", ""]);
  const [$80g, set$80g] = useState([""]);
  const [$80gg, set$80gg] = useState([""]);
  const [suggestions, setSuggestions] = useState([]);

  const [year, setYear] = useState(process.env.REACT_APP_CURRENT_YEAR);
  const { userId } = useParams();
  const [userData, setUserData] = useState({});
  const [questData, setQuestData] = useState({});
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS.split(",");
  const salesEmails = process.env.REACT_APP_SALES_EMAILS.split(",");
  const opsEmails = process.env.REACT_APP_OPS_EMAILS.split(",");

  const [userType, setUserType] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [makeRequest, setMakeRequest] = useState(false);

  const [openMonth, setOpenMonth] = useState(null); // State to track the open month

  const handleAccordionToggle = (month) => {
    if (openMonth !== month) {
      setOpenMonth(month);
    } else {
      setOpenMonth(null); // Close the accordion if the same month is clicked again
    }
  };

  useEffect(() => {
    // const unsubscribe = onAuthStateChanged(auth, async (user) => {
    //   if (!user) {
    //     return navigate("/login");
    //   }
    //   const userEmail = user.email;
    //   setUserName(pan);
    //   const AdminId = adminEmails.includes(userEmail);

    //   const isEmployee = employeeEmails.includes(userEmail);
    //   if (AdminId || isEmployee) {
    //     setUserName(user.displayName);
    //     setUser(user);
    //     if(AdminId) {
    //       setUserType("ADMIN");
    //       setIsAdmin(true);
    //     } else {
    //       setUserType("EMPLOYEE");
    //       setIsAdmin(false);
    //     }
    //     setMakeRequest(true);
    //   }
    //   else {
    //     auth
    //       .signOut()
    //       .then(() => {
    //         alert(
    //           "You are not authorized to access this page. Please contact the administrator."
    //         );
    //         return navigate("/login");
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // });
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        return navigate("/login");
      }
      const userEmail = user.email;
      setUserName(pan);
      const AdminId = adminEmails.includes(userEmail);
      const SalesId = salesEmails.includes(userEmail);
      const OpsId = opsEmails.includes(userEmail);
      if (AdminId || SalesId || OpsId) {
        setUserName(user.displayName);
        setUser(user);
        // if (AdminId) {
        //   setUserType("ADMIN");
        //   setIsAdmin(true);
        // } else if {
        //   setUserType("OPS");
        //   setIsAdmin(false);
        // } else {
        //   setUserType("SALES");
        //   setIsAdmin(false);
        // }
        {
          AdminId ? (
            <>
              {setUserType("ADMIN")}
              {setIsAdmin(true)}
            </>
          ) : OpsId ? (
            <>
              {setUserType("OPS")}
              {setIsAdmin(false)}
            </>
          ) : (
            <>
              {setUserType("SALES")}
              {setIsAdmin(false)}
            </>
          );
        }
        setMakeRequest(true);
      } else {
        auth
          .signOut()
          .then(() => {
            alert(
              "User You are not authorized to access this page. Please contact the administrator."
            );
            return navigate("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    return () => unsubscribe;
  }, [navigate]);

  useEffect(() => {
    const FetchQuest = async (currentYear) => {
      const docRef = doc(db, currentYear.toString(), userId);
      const docSnap = await getDoc(docRef);

      const docRefUser = doc(db, "users", userId);
      const docSnapUser = await getDoc(docRefUser);

      if (!docSnap.exists() || !docSnapUser.exists()) {
        alert(
          "This user's data does not exist for the selected Fiscal or No such User Found"
        );
        throw new Error("No such User Found!");
      }
      if (!docSnap.data()) {
        throw new Error("User data is undefined");
      }
      const res = analyseRes(docSnap.data()?.question);
      console.log("response is set as", res);
      setTaxPaid(docSnap.data()?.taxPaid);
      console.log("taxpaid is .........", docSnap.data()?.taxPaid);

      const temp = docSnap.data()?.question;
      console.log("temp is ....", temp[0]);
      const userRes = docSnapUser.data();

      setName(userRes.name);
      setPan(userRes.pan);
      setTAN(userRes.TAN);
      setefillingPass(userRes.efillingPass);
      setEmail(userRes.email);
      setMobile(userRes.mobile);
      setPoliceStation(userRes.policeStation);
      setAadhaar(userRes.aadhaar);
      setAccountNumber(userRes.accountNumber);
      setIfscCode(userRes.IFSC);
      setEmployeeId(userRes.employeeId);
      setDesignation(userRes.Designation);
      setOfficeAddress(userRes.officeAddress);
      setArea(userRes.area);
      setRoleSet(userRes.roleSet);
      setItrType(userRes.itrType);

      set$80c(temp.$80c);
      setGross(temp.gross);
      setRecRent(temp.recRent);
      setTax(temp.tax);
      setInterest(temp.interest);
      setNps(temp.nps);
      set$80d(temp.$80d);
      set$80dd(temp.$80dd);
      set$80ddb(temp.$80ddb);
      set$80g(temp.$80g);
      set$80gg(temp.$80gg);
      setSuggestions(temp.suggestions);

      setTotalSalary(res?.incomeData[0].amt);
      setHouseRent(res?.incomeData[1].amt);
      setOtherIncome(res?.incomeData[2].amt);
      console.log("response is .......", res);

      setcostTourAllow(res?.allowancesData[0].amt);
      setchildEduAllow(res?.allowancesData[1].amt);
      setrentAllow(res?.allowancesData[2].amt);
      setTravelAllow(res?.allowancesData[3].amt);
      setotherAllow(res?.allowancesData[4].amt);

      setQuestData(res);

      setDeductionData(res?.deductionData);
    };
    FetchQuest(year);
  }, [userId, year]);

  const setVerified = async () => {
    const docRef = doc(db, year, userId);
    await updateDoc(docRef, {
      isVerified: true,
    });
    if (!docRef) {
      alert(
        "This user's data does not exist for the selected Fiscal, Hence can not Update"
      );
    }
    alert("User Verified Successfully");
  };

  const updateData = async () => {
    const docRef = doc(db, year, userId);
    const data = {
      $80c,
      gross,
      recRent,
      tax,
      nps,
      $80d,
      $80dd,
      $80ddb,
      $80g,
      $80gg,
      interest,
      suggestions,
    };
    const data1 = {
      taxPaid,
    };
    await updateDoc(docRef, {
      question: data,
      taxPaid: taxPaid,
    });
    alert("Data Updated Successfully 1");
  };

  const updateUserData = async () => {
    const docRef2 = doc(db, "users", userId);
    const data2 = {
      name,
      pan,
      TAN,
      efillingPass,
      email,
      mobile,
      policeStation,
      aadhaar,
      accountNumber,
      ifscCode,
      employeeId,
      Designation,
      officeAddress,
      area,
      itrType,
    };
    await updateDoc(docRef2, data2);
    alert("User Data Updated Successfully");
  };
  // FetchData();
  const accountInfo = [
    {
      label: "Name",
      value: name,
      name: "name",
      setValue: setName,
      required: true,
    },
    {
      label: "PAN",
      value: pan,
      name: "pan",
      setValue: setPan,
      required: true,
    },
    {
      label: "TAN",
      value: TAN,
      name: "TAN",
      setValue: setTAN,
      required: true,
    },
    {
      label: "E-Filling Password",
      value: efillingPass,
      name: "efillingPass",
      setValue: setefillingPass,
      required: true,
    },
    {
      label: "Email",
      value: email,
      name: "email",
      setValue: setEmail,
      required: true,
    },
    {
      label: "Mobile",
      value: mobile,
      name: "mobile",
      setValue: setMobile,
      required: true,
    },
    {
      label: "Police Station",
      value: policeStation,
      name: "policeStation",
      setValue: setPoliceStation,
      required: true,
    },
    {
      label: "Aadhaar Number",
      value: aadhaar,
      name: "aadhaar",
      setValue: setAadhaar,
      required: true,
    },
    {
      label: "Bank Account Number",
      value: accountNumber,
      name: "accountNumber",
      setValue: setAccountNumber,
      required: true,
    },
    {
      label: "IFSC Code",
      value: ifscCode,
      name: "ifscCode",
      setValue: setIfscCode,
      required: true,
    },
    {
      label: "Employee ID",
      value: employeeId,
      name: "employeeId",
      setValue: setEmployeeId,
      required: true,
    },
    {
      label: "Designation",
      value: Designation,
      name: "Designation",
      setValue: setDesignation,
      required: true,
    },
    {
      label: "Office Address",
      value: officeAddress,
      name: "officeAddress",
      setValue: setOfficeAddress,
      required: true,
    },
    {
      label: "Area",
      value: area,
      name: "area",
      setValue: setArea,
      required: true,
    },
    {
      label: "ITR Type",
      value: itrType,
      name: "itrType",
      setValue: setItrType,
      required: true,
    },
  ];

  const DownloadDataUser = () => {
    const headers = [
      { label: "Sno", key: "sno" },
      { label: "Particulars", key: "particulars" },
      { label: "Income", key: "income" },
      { label: "Deductions", key: "deductions" },
      { label: "Final Amount", key: "finalAmount" },
    ];

    var totalDeduction = 0;
    var totatlIncome = totalSalary + houseRent + otherIncome;
    questData.deductionData
      ? questData.deductionData.map((item, index) => {
        var innermap = item;
        totalDeduction += innermap.amt;
      })
      : console.log("");

    let taxableIncome = totatlIncome - totalDeduction;
    let initialTax = 0;
    let edCess = 0;
    let taxPayable = 0;
    if (taxableIncome >= 0 && taxableIncome < 500001) {
      initialTax = 0;
    } else if (taxableIncome < 1000000) {
      initialTax = 12500 + 0.2 * (taxableIncome - 500000);
    } else if (taxableIncome >= 1000001) {
      initialTax = 112500 + 0.3 * (taxableIncome - 1000000);
    }
    edCess = initialTax * 0.04;
    taxPayable = initialTax + edCess;
    const data = [
      {
        sno: "1",
        particulars: "Income from Salary",
        income: "",
        deductions: "",
        finalAmount: totalSalary,
      },
      {
        sno: "",
        particulars: "Income from House Property",
        income: "B",
        deductions: "",
        finalAmount: houseRent,
      },
      {
        sno: "",
        particulars: "Income from Other Sources",
        income: "C",
        deductions: "",
        finalAmount: otherIncome,
      },
      {
        sno: "",
        particulars: "Total Inome",
        income: "",
        deductions: "",
        finalAmount: totatlIncome,
      },
      { sno: "", particulars: "", income: "", deductions: "", finalAmount: "" },

      {
        sno: "2",
        particulars: "Deductions",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars: "Slab wise",
        income: "",
        deductions: "",
        finalAmount: "",
      },

      {
        sno: "",
        particulars: "Total Deduction",
        income: "",
        deductions: "",
        finalAmount: totalDeduction,
      },
      { sno: "", particulars: "", income: "", deductions: "", finalAmount: "" },
      {
        sno: "",
        particulars: "Taxable Income",
        income: "(Total Income - Total Deductions)",
        deductions: "",
        finalAmount: totatlIncome - totalDeduction,
      },
      { sno: "", particulars: "", income: "", deductions: "", finalAmount: "" },

      {
        sno: "",
        particulars: "Tax As per old regime",
        income: "",
        deductions: "",
        finalAmount: "Y",
      },
      {
        sno: "",
        particulars: "Tax Payable",
        income: "",
        deductions: "As per Old Slab",
        finalAmount: taxPayable,
      },
      {
        sno: "",
        particulars: "Add:- Education cess 4% on Tax Payable",
        income: "",
        deductions: "",
        finalAmount: edCess,
      },
      { sno: "", particulars: "", income: "", deductions: "", finalAmount: "" },

      {
        sno: "",
        particulars: "Total tax Payable",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars: "(Final Amount Displayed)",
        income: "",
        deductions: "",
        finalAmount: "Amount",
      },
      { sno: "", particulars: "", income: "", deductions: "", finalAmount: "" },

      {
        sno: "",
        particulars: "Note:",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars: "1. If Income Below 5Lakhs then Zero Tax",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars: "Formula :-",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars: "Tax Calculated Final - Rebate by Govt (Same Amount)",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars: "2. If Amount above 5Lakhs, entire Slab Rate applied",
        income: "",
        deductions: "",
        finalAmount: "",
      },
      {
        sno: "",
        particulars:
          "https://www.incometax.gov.in/iec/foportal/help/individual/return-applicable-1#taxslabs",
        income: "",
        deductions: "",
        finalAmount: "",
      },
    ];

    return (
      <CSVLink data={data} headers={headers} filename="user_data.csv">
        <div className="black-box">
          <button className="btn btn-primary rounded-pill black-button">
            Download
          </button>
          <img src="https://img.icons8.com/ios/50/000000/save--v1.png" />
        </div>
      </CSVLink>
    );
  };
  const checkRequiredFields = () => {
    const unfilledFields = accountInfo.filter(
      (item) => item.required && !item.value
    );
    return unfilledFields.length === 0;
  };

  const [copySuccess, setCopySuccess] = useState(false); // State to track copy success

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1500);
    });
  };

  return (
    <div className="p-3">
      <TopNav userName={userName} />
      <div className="year-selector">
        <div className="year-box">
          <h3>Punched Data for</h3>
          <YearSelector year={year} setYear={setYear} />
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-4 m-3 p-3 user-details">
          <h4>User Details</h4>
          <div className="line"></div>
          {accountInfo.map((item) => (
            <div key={item.name}>
              <p>
                <b>{item.label}</b>
                {item.required && <span style={{ color: "red" }}>*</span>}
              </p>
              {item.label === "ITR Type" ? (
                <select
                  name={item.name}
                  className="data-box"
                  value={item.value}
                  readOnly={editable ? null : "readOnly"}
                  onChange={(e) => item.setValue(e.target.value)}
                >
                  <option value="ITR-1">ITR-1</option>
                  <option value="ITR-2">ITR-2</option>
                  <option value="ITR-3">ITR-3</option>
                  <option value="ITR-4">ITR-4</option>
                  <option value="ITR-5">ITR-5</option>
                  <option value="ITR-6">ITR-6</option>
                  <option value="ITR-7">ITR-7</option>
                </select>
              ) : (
                <>
                  {item.label === "E-Filling Password" ? (
                    <div className="password-input-container">
                      <input
                        name={item.name}
                        className="data-box"
                        type="password"
                        placeholder={"Enter " + item.label}
                        style={{ border: "none" }}
                        value={item.value}
                        readOnly={editable ? null : "readOnly"}
                        onChange={(e) => item.setValue(e.target.value)}
                      />
                      <MdContentCopy
                        className="copy-icon"
                        onClick={() => copyToClipboard(item.value)}
                      />
                      {/* Copy success message for E-Filling Password */}
                      {copySuccess && (
                        <span className="copy-success">Copied!</span>
                      )}
                    </div>
                  ) : (
                    <input
                      name={item.name}
                      className="data-box"
                      type="text"
                      placeholder={"Enter " + item.label}
                      style={{ border: "none" }}
                      value={item.value}
                      readOnly={editable ? null : "readOnly"}
                      onChange={(e) => item.setValue(e.target.value)}
                    />
                  )}
                </>
              )}
            </div>
          ))}

          {/* {accountInfo.map((item) => (
          <div key={item.name}>
            <p>
              <b>{item.label}</b>
              {item.required && <span style={{ color: "red" }}>*</span>}
            </p>
            <input
                name={item.name}
                className="data-box"
                type="text"
                placeholder={"Enter " + item.label}
                style={{ border: "none" }}
                value={item.value}
                readOnly={editable ? null : "readOnly"}
                onChange={(e) => item.setValue(e.target.value)}
              /></>
            );
          })} */}

          {/* <p>
            <b>Name</b>
          </p>
          <div className="data-box">{userData.name ? userData.name : "--"}</div>
          <p>
            <b>PAN</b>
          </p>
          <div className="data-box">{userData.pan ? userData.pan : "--"}</div>
          <p>
            <b>E-Filling Password</b>
          </p>
          <div className="data-box">{userData.efilling ? userData.efilling : "--"}</div>
          <p>
            <b>Email</b>
          </p>
          <div className="data-box">
            {userData.email ? userData.email : "--"}
          </div>
          <p>
            <b>Mobile</b>
          </p>
          <div className="data-box">
            {userData.mobile ? userData.mobile : "--"}
          </div>
          <p>
            <b>Police Station</b>
          </p>
          <div className="data-box">{userData.policeStation ? userData.policeStation : "--"}</div>
          <p>
            <b>Aadhaar Number</b>
          </p>
          <div className="data-box">
            {userData.aadhar ? userData.aadhar : "--"}
          </div>
          <p>
            <b>Bank Acount Number</b>
          </p>
          <div className="data-box">
            {userData.accountNumber ? userData.accountNumber : "--"}
          </div>
          <p>
            <b>IFSC</b>
          </p>
          <div className="data-box">{userData.IFSC ? userData.IFSC : "--"}</div>
          <p>
            <b>Employee ID</b>
          </p>
          <div className="data-box">
            {userData.employeeId ? userData.employeeId : "--"}
          </div>
          <p>
            <b>Office Adress</b>
          </p>
          <div className="data-box">
            {userData.officeAddress ? userData.officeAddress : "--"}
          </div> */}
        </div>

        <div className="col-7 m-3 row" style={{ height: "fit-content" }}>
          <div className="col-6 mb-3">
            <div className="user-details p-3 light-details mb-4">
              <h4>Annual Income</h4>
              <div className="line" style={{ borderColor: "black" }}></div>
              <div className="data-box data-box-right">
                <span>Income from salary</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setGross([e.target.value]);
                    }
                  }}
                  value={gross[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Monthly Income from Rent on House/Property</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setRecRent([e.target.value]);
                    }
                  }}
                  value={recRent[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Income from Interest on FD</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setInterest((prev) => {
                        const temp = [...prev];
                        temp[0] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={interest[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Income from Interest on RD</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setInterest((prev) => {
                        const temp = [...prev];
                        temp[1] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={interest[1]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Income from Interest on other Investments</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setInterest((prev) => {
                        const temp = [...prev];
                        temp[2] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={interest[2]}
                />{" "}
              </div>
            </div>
            {/* salary Exemption Start */}
            <div className="user-details p-3 light-details mb-4">
              <h4>Salary Exemption</h4>
              <div className="line" style={{ borderColor: "black" }}></div>
              <div className="data-box data-box-right">
                <span>Cost of travel on tour or transfer, maintainance of uniform</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setcostTourAllow([e.target.value]);
                    }
                  }}
                  value={costTourAllow}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Children Education allowance, Hostel allowance</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setchildEduAllow([e.target.value]);
                    }
                  }}
                  value={childEduAllow}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>House Rent Allowance</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setrentAllow([e.target.value]);
                    }
                  }}
                  value={rentAllow}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Travel Allowance</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setTravelAllow([e.target.value]);
                    }
                  }}
                  value={TravelAllow}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Other Allowances</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      setotherAllow([e.target.value]);
                    }
                  }}
                  value={otherAllow}
                />{" "}
              </div>
            </div>
            {/* salary Excemption end */}

            <div className="col-12 mb-3">
              <div className="user-details p-3 light-details">
                <h4>Tax Paid</h4>

                {/* accordion starts */}
                <div className="accordion" id="accordionExample">

                  {/* quater1 starts */}
                  <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseQ1"
                        aria-expanded="false"
                        aria-controls="collapseQ1"
                      >
                        <b>Q1</b>
                      </button>
                    </h2>
                    <div
                      id="collapseQ1"
                      className={`accordion-collapse collapse ${openMonth === "q1" ? "show" : ""}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="dark-line"></div>

                      <div className="accordion-body">
                        {/* Sub-fields for Jan */}
                        <div className="mb-3">
                          <label htmlFor="receiptNoJan" className="form-label">
                            Receipt No. (Section 200)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="receiptNoJan"
                            value={taxPaid[0] ? taxPaid[0].recNo : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[0].recNo = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="salaryCreditedJan"
                            className="form-label"
                          >
                            Salary Credited
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="salaryCreditedJan"
                            value={taxPaid[0] ? taxPaid[0].salary : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[0].salary = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* Jan ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionJan">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseJan"
                                aria-expanded={openMonth === "jan"}
                                aria-controls="collapseJan"
                                // style={{ borderRadius: "0.25rem" }}
                                onClick={() => handleAccordionToggle("jan")}
                              >
                                <b>April</b>
                              </button>
                            </h2>
                            <div
                              id="collapseJan"
                              className={`accordion-collapse collapse ${openMonth === "jan" ? "show" : ""}`}
                              data-bs-parent="#accordionJan"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[0] ? taxPaid[0].april.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].april.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].april.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].april.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}

                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].april.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].april.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}

                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].april.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].april.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}

                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Jan */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FEB ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionFeb">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFeb"
                                aria-expanded={openMonth === "feb"}
                                aria-controls="collapseFeb"
                                onClick={() => handleAccordionToggle("feb")}
                              >
                                <b>May</b>
                              </button>
                            </h2>
                            <div
                              id="collapseFeb"
                              className={`accordion-collapse collapse ${openMonth === "feb" ? "show" : ""}`}
                              data-bs-parent="#accordionFeb"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[0] ? taxPaid[0].may.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].may.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}

                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].may.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].may.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].may.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].may.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].may.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].may.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Feb */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* MARCH ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionMar">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseMar"
                                aria-expanded={openMonth === "mar"}
                                aria-controls="collapseMar"
                                onClick={() => handleAccordionToggle("mar")}
                              >
                                <b>June</b>
                              </button>
                            </h2>
                            <div
                              id="collapseMar"
                              className={`accordion-collapse collapse ${openMonth === "mar" ? "show" : ""}`}
                              data-bs-parent="#accordionMar"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[0] ? taxPaid[0].june.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].june.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].june.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].june.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].june.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].june.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[0] ? taxPaid[0].june.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[0].june.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for March */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* QUARTER 2 STARTS  */}
                  <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseQ2"
                        aria-expanded="false"
                        aria-controls="collapseQ2"
                      >
                        <b>Q2</b>
                      </button>
                    </h2>
                    <div
                      id="collapseQ2"
                      className={`accordion-collapse collapse ${openMonth === "q2" ? "show" : ""}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="dark-line"></div>

                      <div className="accordion-body">
                        {/* Sub-fields for Jan */}
                        <div className="mb-3">
                          <label htmlFor="receiptNoJan" className="form-label">
                            Receipt No. (Section 200)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="receiptNoJan"
                            value={taxPaid[1] ? taxPaid[1].recNo : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[1].recNo = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="salaryCreditedJan"
                            className="form-label"
                          >
                            Salary Credited
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="salaryCreditedJan"
                            value={taxPaid[1] ? taxPaid[1].salary : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[1].salary = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* JAN ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionJan2">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseJan2"
                                aria-expanded={openMonth === "jan"}
                                aria-controls="collapseJan2"
                                onClick={() => handleAccordionToggle("jan")}

                              >
                                <b>July</b>
                              </button>
                            </h2>
                            <div
                              id="collapseJan2"
                              className={`accordion-collapse collapse ${openMonth === "jan" ? "show" : ""}`}
                              data-bs-parent="#accordionJan2"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[1] ? taxPaid[1].july.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].july.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].july.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].july.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].july.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].july.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].july.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].july.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Jan */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FEB ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionFeb2">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFeb2"
                                aria-expanded={openMonth === "feb"}
                                aria-controls="collapseFeb2"
                                onClick={() => handleAccordionToggle("feb")}
                              >
                                <b>August</b>
                              </button>
                            </h2>
                            <div
                              id="collapseFeb2"
                              className={`accordion-collapse collapse ${openMonth === "feb" ? "show" : ""}`}
                              data-bs-parent="#accordionFeb2"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[1] ? taxPaid[1].aug.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].aug.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].aug.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].aug.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].aug.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].aug.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].aug.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].aug.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Feb */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* MARCH ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionMar2">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseMar2"
                                aria-expanded={openMonth === "mar"}
                                aria-controls="collapseMar2"
                                onClick={() => handleAccordionToggle("mar")}
                              >
                                <b>September</b>
                              </button>
                            </h2>
                            <div
                              id="collapseMar2"
                              className={`accordion-collapse collapse ${openMonth === "mar" ? "show" : ""}`}
                              data-bs-parent="#accordionMar2"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[1] ? taxPaid[1].sept.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].sept.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].sept.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].sept.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].sept.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].sept.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[1] ? taxPaid[1].sept.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[1].sept.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for March */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseQ3"
                        aria-expanded="false"
                        aria-controls="collapseQ3"
                      >
                        <b>Q3</b>
                      </button>
                    </h2>
                    <div
                      id="collapseQ3"
                      className={`accordion-collapse collapse ${openMonth === "q3" ? "show" : ""}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="dark-line"></div>

                      <div className="accordion-body">
                        {/* Sub-fields for Jan */}
                        <div className="mb-3">
                          <label htmlFor="receiptNoJan" className="form-label">
                            Receipt No. (Section 200)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="receiptNoJan"
                            value={taxPaid[2] ? taxPaid[2].recNo : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[2].recNo = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="salaryCreditedJan"
                            className="form-label"
                          >
                            Salary Credited
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="salaryCreditedJan"
                            value={taxPaid[2] ? taxPaid[2].salary : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[2].salary = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* JAN ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionJan3">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseJan3"
                                aria-expanded={openMonth === "jan"}
                                aria-controls="collapseJan3"
                                onClick={() => handleAccordionToggle("jan")}
                              >
                                <b>October</b>
                              </button>
                            </h2>
                            <div
                              id="collapseJan3"
                              className={`accordion-collapse collapse ${openMonth === "jan" ? "show" : ""}`}
                              data-bs-parent="#accordionJan3"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[2] ? taxPaid[2].oct.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].oct.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].oct.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].oct.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].oct.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].oct.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].oct.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].oct.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Jan */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FEB ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionFeb3">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFeb3"
                                aria-expanded={openMonth === "feb"}
                                aria-controls="collapseFeb3"
                                onClick={() => handleAccordionToggle("feb")}
                              >
                                <b>November</b>
                              </button>
                            </h2>
                            <div
                              id="collapseFeb3"
                              className={`accordion-collapse collapse ${openMonth === "feb" ? "show" : ""}`}
                              data-bs-parent="#accordionFeb3"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[2] ? taxPaid[2].nov.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].nov.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].nov.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].nov.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].nov.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].nov.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].nov.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].nov.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Feb */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* MARCH ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionMar3">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseMar3"
                                aria-expanded={openMonth === "mar"}
                                aria-controls="collapseMar2"
                                onClick={() => handleAccordionToggle("mar")}
                              >
                                <b>December</b>
                              </button>
                            </h2>
                            <div
                              id="collapseMar3"
                              className={`accordion-collapse collapse ${openMonth === "mar" ? "show" : ""}`}
                              data-bs-parent="#accordionMar3"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[2] ? taxPaid[2].dec.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].dec.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].dec.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].dec.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].dec.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].dec.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[2] ? taxPaid[2].dec.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[2].dec.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for March */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* QUARTER 4 STARTS  */}
                  <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseQ4"
                        aria-expanded="false"
                        aria-controls="collapseQ4"
                      >
                        <b>Q4</b>
                      </button>
                    </h2>
                    <div
                      id="collapseQ4"
                      className={`accordion-collapse collapse ${openMonth === "q4" ? "show" : ""}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="dark-line"></div>

                      <div className="accordion-body">
                        {/* Sub-fields for Jan */}
                        <div className="mb-3">
                          <label htmlFor="receiptNoJan" className="form-label">
                            Receipt No. (Section 200)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="receiptNoJan"
                            value={taxPaid[3] ? taxPaid[3].recNo : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[3].recNo = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="salaryCreditedJan"
                            className="form-label"
                          >
                            Salary Credited
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="salaryCreditedJan"
                            value={taxPaid[3] ? taxPaid[3].salary : null}
                            onChange={(e) => {
                              if (editable) {
                                setTaxPaid((prev) => {
                                  let temp = [...prev];
                                  temp[3].salary = e.target.value;
                                  return temp;
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* JAN ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionJan4">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseJan4"
                                aria-expanded={openMonth === "jan"}
                                aria-controls="collapseJan4"
                                onClick={() => handleAccordionToggle("jan")}
                              >
                                <b>Jan</b>
                              </button>
                            </h2>
                            <div
                              id="collapseJan4"
                              className={`accordion-collapse collapse ${openMonth === "jan" ? "show" : ""}`}
                              data-bs-parent="#accordionJan4"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[3] ? taxPaid[3].jan.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].jan.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].jan.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].jan.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].jan.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].jan.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].jan.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].jan.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Jan */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FEB ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionFeb3">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFeb4"
                                aria-expanded={openMonth === "feb"}
                                aria-controls="collapseFeb4"
                                onClick={() => handleAccordionToggle("feb")}
                              >
                                <b>Feb</b>
                              </button>
                            </h2>
                            <div
                              id="collapseFeb4"
                              className={`accordion-collapse collapse ${openMonth === "feb" ? "show" : ""}`}
                              data-bs-parent="#accordionFeb4"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[3] ? taxPaid[3].feb.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].feb.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].feb.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].feb.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].feb.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].feb.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].feb.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].feb.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for Feb */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* MARCH ACCORDION */}
                      <div className="accordion-body">
                        <div className="accordion" id="accordionMar4">
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button shadow-none text-dark bg-dark-gray header-pill"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseMar4"
                                aria-expanded={openMonth === "mar"}
                                aria-controls="collapseMar4"
                                onClick={() => handleAccordionToggle("mar")}
                              >
                                <b>March</b>
                              </button>
                            </h2>
                            <div
                              id="collapseMar4"
                              className={`accordion-collapse collapse ${openMonth === "mar" ? "show" : ""}`}
                              data-bs-parent="#accordionMar4"
                            >
                              <div className="accordion-body">
                                {/* Sub-fields for Jan */}
                                <div className="mb-3">
                                  <label
                                    htmlFor="receiptNoJan"
                                    className="form-label"
                                  >
                                    Receipt No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="receiptNoJan"
                                    value={taxPaid[3] ? taxPaid[3].mar.recNo24G : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].mar.recNo24G = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    DDO No. 24G
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].mar.DDO : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].mar.DDO = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Date Of Voucher
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].mar.date : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].mar.date = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="salaryCreditedJan"
                                    className="form-label"
                                  >
                                    Tax Deposited
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="salaryCreditedJan"
                                    value={taxPaid[3] ? taxPaid[3].mar.taxDeposited : null}
                                    onChange={(e) => {
                                      if (editable) {
                                        setTaxPaid((prev) => {
                                          let temp = [...prev];
                                          temp[3].mar.taxDeposited = e.target.value;
                                          return temp;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {/* Implement your code for the remaining sub-fields for March */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
              {/* <div className="col-12 mt-4">
                <div className="user-details p-3 light-details">
                  <h4>Deductions Applicable</h4>
                  <div className="line" style={{ borderColor: "black" }}></div>
                  {questData.deductionData
                    ? questData.deductionData.map((item, index) => {
                      if (item.valid) {
                        return (
                          <div
                            key={item.id}
                            className="data-box data-box-right"
                          >
                            <span>{item.name}</span>{" "}
                            <input
                              className="user-update"
                              type="number"
                              onChange={(e) => {
                                if (editable) {
                                  setDeductionData((prev) => {
                                    let temp = [...prev];
                                    temp[index].amt = e.target.value;
                                    return temp;
                                  });
                                }
                              }}
                              value={deductionData[index].amt}
                            />{" "}
                          </div>
                        );
                      }
                    })
                    : null}
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="user-details p-3 light-details">
              <h4>Deductions Applicable</h4>
              <div className="line" style={{ borderColor: "black" }}></div>
              <div className="data-box data-box-right">
                <span>80C - LIC</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[0] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80C - Home Loan</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[1] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[1]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80C - Tution Fees</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[2] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[2]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80C - GPF</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[3] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[3]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Municipal Tax</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    setTax([e.target.value]);
                  }}
                  value={tax}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>NPS</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    setNps([e.target.value]);
                  }}
                  value={nps}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80D - Health Below 60</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80d((prev) => {
                        let temp = [...prev];
                        temp[0] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80d[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80D - Health Above 60</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80d((prev) => {
                        let temp = [...prev];
                        temp[1] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80d[1]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80dd - Disability(0/1/2)</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  title="Enter 0 for no disability, 1 for less than 80% and 2 for more than 80%"
                  onChange={(e) => {
                    if (editable) {
                      set$80dd((prev) => {
                        if ([e.target.value] in ["0", "1", "2"]) {
                          return [e.target.value];
                        } else return prev;
                      });
                    }
                  }}
                  value={$80dd[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80DDB - Medical Below 60</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80ddb((prev) => {
                        let temp = [...prev];
                        temp[0] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80ddb[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80DDB - Medical Above 60</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80ddb((prev) => {
                        let temp = [...prev];
                        temp[1] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80ddb[1]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80G - Donation</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    set$80g([e.target.value]);
                  }}
                  value={$80g}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80GG - Rent Paid</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    set$80gg([e.target.value]);
                  }}
                  value={$80gg}
                />{" "}
              </div>
            </div>

            <div className="user-details p-3 light-details">
              <h4>Docs Uploaded</h4>
              <div className="line" style={{ borderColor: "black" }}></div>
              <div className="data-box data-box-right">
                <span>80C - LIC</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[0] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[0]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80C - Home Loan</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[1] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[1]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80C - Tution Fees</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[2] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[2]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80C - GPF</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80c((prev) => {
                        let temp = [...prev];
                        temp[3] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80c[3]}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>Municipal Tax</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    setTax([e.target.value]);
                  }}
                  value={tax}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>NPS</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    setNps([e.target.value]);
                  }}
                  value={nps}
                />{" "}
              </div>
              <div className="data-box data-box-right">
                <span>80D - Health Below 60</span>{" "}
                <input
                  className="user-update"
                  type="number"
                  onChange={(e) => {
                    if (editable) {
                      set$80d((prev) => {
                        let temp = [...prev];
                        temp[0] = e.target.value;
                        return temp;
                      });
                    }
                  }}
                  value={$80d[0]}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-table">
        <div className="row">
          <div className="button-container">
            <div className="black-box">
              <button
                className="btn btn-primary rounded-pill black-button"
                onClick={() => {
                  setEditable(true);
                }}
              >
                Edit
              </button>
              <img src="https://img.icons8.com/ios/50/000000/save--v1.png" />
            </div>

            <div className="black-box">
              <button
                className="btn btn-primary rounded-pill black-button "
                onClick={() => {
                  if (!checkRequiredFields()) {
                    alert("Please fill in all required fields.");
                    return;
                  }
                  updateUserData();
                  updateData();
                  setEditable(false);
                }}
              >
                Save
              </button>
              <img src="https://img.icons8.com/ios/50/000000/save--v1.png" />
            </div>

            <div className="black-box">
              <button
                className="btn btn-primary rounded-pill black-button "
                onClick={() => {
                  setVerified();
                }}
              >
                Verify
              </button>
              <img src="https://img.icons8.com/ios/50/000000/save--v1.png" />
            </div>

            <DownloadDataUser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
