import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { auth, db } from "../firebase";
import { doc, setDoc, increment, getDoc } from "firebase/firestore";
import { BN, updateProfile, onAuthStateChanged } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

import { responseSchema } from "../data/userLibrary";

import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { TopNav } from "./blocks/topNav";
import "./addUser.css";

// let passwordGenerator = require("generate-password");

const NewUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [userName, setUserName] = useState("User Name");

  const [name, setName] = useState("");
  const [pan, setPan] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setuserType] = useState("");
  const [itrType, setItrType] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [actualFees, setActualFees] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [Fees, setFees] = useState(0);
  const [PaidFees, setPaidFees] = useState(0);
  const [PendingFees, setPendingFees] = useState(0);

  const [services, setServices] = useState([]);
  const [ShareMessage, setShareMessage] = useState(false);
  const [Notification, setNotification] = useState(false);
  const [NotificationType, setNotificationType] = useState("");
  const [currentInvNo, setCurrentInvNo] = useState(0);
  const [Message, setMessage] = useState("");

  const [portalFeeChecked, setPortalFeeChecked] = useState(false);
  const [itrNilChecked, setitrNilChecked] = useState(false);
  const [itrSalaryChecked, setitrSalaryChecked] = useState(false);
  const [SharesBelow10LChecked, setSharesBelow10LChecked] = useState(false);
  const [SharesBelow5LChecked, setSharesBelow5LChecked] = useState(false);
  const [SharesBelow20LChecked, setSharesBelow20LChecked] = useState(false);
  const [ItrBusinessChecked, setItrBusinessChecked] = useState(false);



  useEffect(() => {
    // Function to fetch the current invoice number from the invoice collection
    const fetchCurrentInvoiceNumber = async () => {
      try {
        const invoiceRef = doc(db, "invoice", "INV"); // Assuming "INV" is the document ID where the current invoice number is stored
        const invoiceSnapshot = await getDoc(invoiceRef);
        if (invoiceSnapshot.exists()) {
          const data = invoiceSnapshot.data();
          console.log("invoice data is ...", data);
          setCurrentInvNo(data.currentInvNo); // Update the state with the current invoice number
        } else {
          console.error("Invoice document does not exist.");
        }
      } catch (error) {
        console.error("Error fetching current invoice number:", error);
      }
    };

    fetchCurrentInvoiceNumber(); // Fetch the current invoice number on component mount
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        return navigate("/login");
      }
      const userEmail = user.email;
      setUserEmail(userEmail);
      alert(userEmail);
      setUserName(pan);
      const AdminId = adminEmails.includes(userEmail);

      if (AdminId) {
        setUserName(user.displayName);
        setUser(user);
        setUserType("ADMIN");
        setIsAdmin(true);
        setMakeRequest(true);
      }
      else {
        auth
          .signOut()
          .then(() => {
            alert(
              "Dashboard You are not authorized to access this page. Please contact the administrator."
            );
            return navigate("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    return () => unsubscribe;
  }, [navigate]);

  useEffect(() => {
    if (Notification) {
      setTimeout(() => {
        setNotification(false);
      }, 3000);
    }
  }, [Notification, setNotification]);

  // Random password Generator
  const randomizePassword = (e) => {
    let max = 99999999;
    let min = 10000000;
    let value = Math.floor(Math.random() * (max - min + 1)) + min;
    setPassword(value.toString());
  }


  // Questions for the FY Year
  const question = responseSchema;

  const [panExist, setPanExist] = useState(false);

  // Check if pan exist or not   
  const checkPanExist = async () => {
    try {
      const userRef = doc(db, "users", pan);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setPanExist(true);
        // console.log("In database");
      } else {
        setPanExist(false);
        // console.log("Not in database");
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  useEffect(() => {
    if (pan.length === 10) {
      checkPanExist();
      // console.log("pan length is 10");
    } else {
      setPanExist(false);
    }
  }, [pan]);

  useEffect(() => {
    const actualfees = parseFloat(actualFees);
    const discountAmount = parseFloat(discount);
    const fees = parseFloat(Fees);
    const paidFees = parseFloat(PaidFees);

    if (!isNaN(actualfees) && !isNaN(discountAmount)) {
      const finalFee = actualfees - discountAmount;
      setFees(finalFee);
    }
    if (!isNaN(fees) && !isNaN(paidFees)) {
      const pendingFees = fees - paidFees;
      setPendingFees(pendingFees);
    }
  }, [actualFees, discount, Fees, PaidFees]);

  //Form Submit Handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (panExist) {
      setNotification(true);
      setNotificationType("error");
      setMessage("PAN already exist!");
      return;
    }

    const emailSplit = email.split("@");
    const emailPAN = emailSplit[0] + "+" + pan + "@" + emailSplit[1];

    if (password.length < 6) {
      setNotification(true);
      setNotificationType("error");
      setMessage("Length of password must be greater than 6!");
      //   newWarn("Length of password must be greater than 6!");
      return;
    } else {

      try {
        // Testing the function
        const functionURL = "https://createuser-63he4lnb3a-uc.a.run.app";
        const response = await fetch(functionURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ emailPAN, password }),
        });
        const invoiceRef = doc(db, "invoice", "INV");
        await setDoc(invoiceRef, { currentInvNo: increment(1) }, { merge: true });
        // console.log(response);
        if (response.ok) {
          // const dataResponse = await response;
          console.log("User registered successfully");

          // Adding further details
          const docRef = doc(db, "users", pan);
          console.log("userType is :", userType);
          const path = -1;
          // user data to be inserted
          const data = {
            name,
            email,
            emailPAN,
            pan,
            mobile,
            userType,
            itrType,
            city,
            area,
            Fees,
            PaidFees,
            PendingFees,
            services,
            invNo: currentInvNo,
          };

          console.log(data);
          setDoc(docRef, data)
            .then(() => {
              console.log("Document has been added successfully");
              // add question blank to yesr
              const currentYear = process.env.REACT_APP_CURRENT_YEAR;
              const field = [
                { jan: 0, feb: 0, mar: 0 },
                { jan: 0, feb: 0, mar: 0 },
                { jan: 0, feb: 0, mar: 0 },
                { jan: 0, feb: 0, mar: 0 },
              ];

              const docRef = doc(db, currentYear.toString(), pan);
              const data = {
                pan,
                emailPAN,
                question,
                path,
                isVerified: false,
                punch: false,
                taxPaid: field,
                Employers: [],
                actualFees,
                discount,
                Fees,
                PaidFees,
                paymentRequired: PaidFees > 199 ? false : true,
                PendingFees,
                services,
                invNo: currentInvNo,
              };
              setDoc(docRef, data);
              // setSuccess(true);
              setShareMessage(true);
              setNotification(true);
              setNotificationType("success");
              setMessage("User successfully added");

            });
        } else {
          const errorData = await response.json();
          console.log("Error in registering user");
          throw new Error(errorData);
        }
      } catch (error) {
        alert(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setShareMessage(false);
        setNotification(true);
        setNotificationType("error");
        setMessage(errorMessage);
        return;
      }
    }
  }

  const resetCheckboxes = () => {
    setPortalFeeChecked(false);
    setitrNilChecked(false);
    setItrBusinessChecked(false)
    setitrSalaryChecked(false);
    setSharesBelow5LChecked(false);
    setSharesBelow10LChecked(false);
    setSharesBelow20LChecked(false);
    // Reset other checkbox state variables as needed...
  };

  const ResetStates = () => {
    setName("");
    setPan("");
    setPassword("");
    setMobile("");
    setEmail("");
    setuserType("");
    setItrType("");
    setCity("");
    setArea("");
    setActualFees(0);
    setDiscount(0);
    setFees(0);
    setPaidFees(0);
    setPendingFees(0);
    setServices([]);
    setShareMessage(false);
    setNotification(false);
    setNotificationType("");
    setMessage("");
    resetCheckboxes();
  }

  const NotificationComponent = (props) => {
    return (<div className={"notification " + props.type}>{props.message}</div>);
  }

  const WhatsappShare = () => {
    return (
      <div className="whatsappmessage">
        <h5>Whatsapp Message Preview</h5>
        <div className="whatsappmessage__message">
          <p>Good day <b>{name}</b>,</p>

          <p>Welcome to Tax Sarthi. We are delighted to have you here. Now file your ITR from the comfort your home.</p>
          <p>Your account has been successfully created by our team. Visit <a href="taxsarthi.com">taxsarthi.com</a> to avail the benefits of the portal.</p>
          <br />
          <p>Your credentials are:</p>
          <p>UserName: <b>{pan}</b></p>
          <p>password: <b>{password}</b></p>
          <br />
          <p>We strive to make your ITR filing smooth.</p>
          <p>Thank you for choosing Tax Sarthi.</p>

          <p>Tax Sarthi,</p>
          <p className="small-font">Apka apna Tax Consultant!</p>
        </div>
        <WhatsappLinkCreator password={password} name={name} Phone={mobile} pan={pan} />
      </div>

    )
  }

  const WhatsappLinkCreator = (props) => {
    let customerName = String(props.name).replace(" ", "%20");
    // To modify the message follow the standards of URL encoder 
    let whatsappLink = `https://api.whatsapp.com/send?phone=+91${props.Phone}&text=Good%20day%20*${customerName}*,%0AWelcome%20to%20*Tax%20Sarthi*.%20We%20are%20delighted%20to%20have%20you%20here.%20Now%20file%20your%20ITR%20from%20the%20comfort%20your%20home.%0A%0AYour%20account%20has%20been%20successfully%20created%20by%20our%20team.%20%0AVisit%20taxsarthi.com%20to%20avail%20the%20benefits%20of%20the%20portal.%0A%0AYour%20credentials%20are:%0A%60%60%60UserName%60%60%60:%20*${props.pan}*%0A%60%60%60Password%60%60%60:%20*${props.password}*%0A%0AWe%20strive%20to%20make%20your%20ITR%20filing%20smooth.%0AThank%20you%20for%20choosing%20*Tax%20Sarthi*.%0A%0A*Tax%20Sarthi*,%0A_Apka%20apna%20Tax%20Consultant!_`;
    return (<Link to={whatsappLink} target="_blank"><button className="share_btn">Share</button></Link>)
  }

  const handleCheckboxChange = (itemName, itemAmount, checked) => {
    if (checked) {
      setActualFees(prevTotal => prevTotal + itemAmount);
      setServices(prevItems => [...prevItems, { name: itemName }]);
    } else {
      setActualFees(prevTotal => prevTotal - itemAmount);
      setServices(prevItems => prevItems.filter(item => item.name !== itemName));
    }
  };

  const AdminOptions = () => {
    return (
      <div className="options_btn">
        <Link className="btn btn_home" to="/">Home</Link>
        <button className="btn btn_addUser" onClick={ResetStates}>Add Another User</button>
      </div>
    )
  }

  return (
    <div className="addUserPage">
      {/* <TopNav userName={userName} /> */}
      {Notification ? <NotificationComponent type={NotificationType} message={Message} /> : ""}
      <TopNav />
      <div className="newUserSection">
        <h2 className="heading">Add New User</h2>
        <form onSubmit={handleSubmit}>
          {/* <input onChange={(e) => {setUserName(e.target.value)}} type="text" name="userName"  placeholder="User name" className="input_field" value={userName} required/> */}
          <legend className="input_legend">
            <label>Name</label>
            <input onChange={(e) => { setName(e.target.value) }} type="text" name="name" placeholder="Full name" className="input_field" value={name !== undefined ? name : ""} required />
          </legend>

          <legend className="input_legend">
            <label>PAN</label>
            <input onChange={(e) => { setPan(e.target.value.toUpperCase()) }} type="text" name="pan" pattern="[A-Z]{5}[0-9]{4}{A-Z}{1}" placeholder="Enter 10 Digit PAN Number" className="input_field " value={pan !== undefined ? pan : ""} required />
            <div className="valdidation">{panExist ? "pan Already Exist in Database" : ""}</div>
          </legend>

          <legend className="input_legend">
            <label>Mobile</label>
            <input onChange={(e) => { setMobile(e.target.value) }} type="number" name="mobile" pattern="[0-9]{10}" placeholder="10 Digit Mobile Number" className="input_field" value={mobile !== undefined ? mobile : ""} required />
          </legend>

          <legend className="input_legend">
            <label>Email</label>
            <input onChange={(e) => { setEmail(e.target.value) }} type="email" name="email" placeholder="Enter Email Address" className="input_field" value={email !== undefined ? email : ""} required />
          </legend>

          <legend className="input_legend">
            <label>Password</label>
            <input onChange={(e) => { setPassword(e.target.value) }} type="password" name="password" placeholder="****" className="input_field" value={password !== undefined ? password : ""} disabled={ShareMessage} required />
            <button type="button" className="btn btn-primary" onClick={randomizePassword} disabled={ShareMessage}>
              Generate Password
            </button>
          </legend>
          <legend className="input_legend">
            <label>User Type</label>
            <select name="userType" id="userType" onChange={(e) => { setuserType(e.target.value) }}>
              <option value="Policemen">Police</option>
              <option value="Defence Personal">Defence Personal</option>
              <option value="Private Corporate Employee">Private Corporate Employee</option>
              <option value="Government Employee">Government Employee</option>
              <option value="Self Employed or Freelancer or Student">Self Employed or Freelancer or Student</option>
            </select>
          </legend>

          <legend className="input_legend">
            <label>ITR Type</label>
            <select name="itrType" id="itrType" onChange={(e) => { setItrType(e.target.value) }}>
              <option value="NIL" selected>ITR-0 NIL</option>
              <option value="ITR-1">ITR-1 Salary</option>
              <option value="ITR-2">ITR-2 Salary + Shares</option>
              <option value="ITR-3">ITR-3 Business + Shares</option>
              <option value="ITR-4">ITR-4 Business</option>
              <option value="ITR-5">ITR-5 Partnership Firm/LLP</option>
              <option value="ITR-6">ITR-6 Company</option>
              <option value="ITR-7">ITR-7 Trust</option>
            </select>
          </legend>

          <legend className="input_legend">
            <label>Service</label>
            <label><input type="checkbox" name="portalFee" onChange={e => {
              setPortalFeeChecked(e.target.checked);
              handleCheckboxChange("portalFee", 299, e.target.checked)
            }}
            checked={portalFeeChecked} /> Tax Sarthi Portal</label>
          </legend>
          <legend className="input_legend">
            <label></label>
            <label><input type="checkbox" name="itrNil" onChange={e => { setitrNilChecked(e.target.checked); handleCheckboxChange("itrNil", 0, e.target.checked) }}
              checked={itrNilChecked} /> NIL</label>
          </legend>
          <legend className="input_legend">
            <label></label>
            <label><input type="checkbox" name="itrSalary" onChange={e => { setitrSalaryChecked(e.target.checked); handleCheckboxChange("itrSalary", 2500, e.target.checked) }} 
              checked={itrSalaryChecked}/> ITR Salary</label>
          </legend>
          <legend className="input_legend">
            <label></label>
            <label><input type="checkbox" name="itrBusiness" onChange={e => { setItrBusinessChecked(e.target.checked); handleCheckboxChange("itrBusiness", 6000, e.target.checked) }} 
              checked={ItrBusinessChecked}/> ITR Business</label>
          </legend>
          <legend className="input_legend">
            <label></label>
            <label><input type="checkbox" name="sharesBelow5L" onChange={e => { setSharesBelow5LChecked(e.target.checked); handleCheckboxChange("sharesBelow5L", 1000, e.target.checked) }} 
              checked={SharesBelow5LChecked}/> Shares Below 5L</label>
          </legend>
          <legend className="input_legend">
            <label></label>
            <label><input type="checkbox" name="sharesBelow10L" onChange={e => { setSharesBelow10LChecked(e.target.checked); handleCheckboxChange("sharesBelow10L", 2000, e.target.checked) }} 
              checked={SharesBelow10LChecked}/> Shares Below 10L</label>
          </legend>
          <legend className="input_legend">
            <label></label>
            <label><input type="checkbox" name="sharesBelow20L" onChange={e => { setSharesBelow20LChecked(e.target.checked); handleCheckboxChange("sharesBelow20L", 3000, e.target.checked) }} 
              checked={SharesBelow20LChecked}/> Shares Below 20L</label>
          </legend>

          <legend className="input_legend">
            <label>City</label>
            <input onChange={(e) => { setCity(e.target.value) }} type="text" name="city" placeholder="City" className="input_field" value={city !== undefined ? city : ""} required />
          </legend>

          <legend className="input_legend">
            <label>Area/Nature</label>
            <input onChange={(e) => { setArea(e.target.value) }} type="text" name="area" placeholder="Area/Nature" className="input_field" value={area !== undefined ? area : ""} required />
          </legend>

          <legend className="input_legend">
            <label>Fees</label>
            <input type="number" name="ActualFees" placeholder="Enter Fees" className="input_field" value={actualFees !== undefined ? actualFees : 0} disabled />
          </legend>

          <legend className="input_legend">
            <label>Discount (in Rs.)</label>
            <input onChange={(e) => { setDiscount(e.target.value) }} type="number" name="Discount" placeholder="Enter Discount Amount" className="input_field" value={discount !== undefined ? discount : 0} />
          </legend>

          <legend className="input_legend">
            <label>Final Fees</label>
            <input type="number" name="Fees" placeholder="Enter Fees" className="input_field" value={Fees !== undefined ? Fees : 0} disabled />
          </legend>

          <legend className="input_legend">
            <label>Paid Fees</label>
            <input onChange={(e) => { setPaidFees(e.target.value) }} type="number" name="PaidFees" placeholder="Enter Paid Fees" className="input_field" value={PaidFees !== undefined ? PaidFees : 0} />
          </legend>

          <legend className="input_legend">
            <label>Pending Fees</label>
            <input type="number" name="PendingFees" placeholder="Enter Pending Fees" className="input_field" value={PendingFees !== undefined ? PendingFees : 0} disabled />
          </legend>

          <input type="reset" className="btn btn-danger" value="Reset" onClick={ResetStates}></input>
          <input type="submit" className="btn btn-success" value="Add User" onClick={handleSubmit}></input>

        </form>
      </div>

      {ShareMessage ? <WhatsappShare /> : ""}

      {ShareMessage ? <AdminOptions /> : ""}

    </div>);
};

export default NewUser;