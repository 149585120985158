import { React, useState } from "react";
import { Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";

export const TopNav = (userName = "NULL") => {
  const [profileUrl, setProfileUrl] = useState([
    "https://tinyurl.com/4ypa9c6z",
  ]);

  onAuthStateChanged(auth, async (user) => {
    if (user.photoURL) {
      setProfileUrl(user.photoURL);
    }
  });

  return (
    <div className="top-nav">
      <Link to="/dashboard">
        <img src="/assets/logo.png" alt="logo" className="logo" />
      </Link>
      <div className="admin">
        <Link to="/profile">
          <p className="admin-name">
            <b>{userName.userName} </b>| {userName.userType}
          </p>
        </Link>
        <div className="admin-logo">
          <img
            src={profileUrl}
            className="img-thumbnail rounded-circle"
            alt="user avatar"
            width="100"
            height={200}
          ></img>
        </div>
      </div>
    </div>
  );
};
