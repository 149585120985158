import {
  collection,
  query,
  onSnapshot,
  where,
  startAfter,
  // limit,
  getDocs,
  orderBy,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "../firebase";


export const updateAssignValue = async (clientPAN, empId) => {
  const LowercaseEmpId = empId.toLowerCase();
  // Selecting the collection
  console.log("Emp ID:", LowercaseEmpId);
  const docRef = doc(db, "users", clientPAN);
  // Update the year here for next FY collection
  const docRefYear = doc(db, "2024-25", clientPAN);
  // updating the value
  try {
    await updateDoc(docRef, {assign:LowercaseEmpId});
    await updateDoc(docRefYear, {assign:LowercaseEmpId});
    alert("Request Recived");
  } catch (error) {
    alert("Error in updation")
    console.log("Error: ", error);
  }
}

export const getAllAsigned = async () => {
  // Selecting the collection
  const docRef = query(collection(db, process.env.REACT_APP_CURRENT_YEAR));
  const querySnapshot = await getDocs(docRef);
  const data = querySnapshot.docs.map((doc) => doc.data());
  return data;

  // Getting current Assigned

}

export const getAssignedUsers = async (userEmail) => {
  try {
    // Query the Firestore collection for users where assign is equal to userEmail
    const assignedUsersRef = collection(db, "users");
    const assignedUsersQuery = query(assignedUsersRef, where("assign", "==", userEmail));
    console.log("assigned users query is : ", assignedUsersQuery);
    const assignedUsersSnapshot = await getDocs(assignedUsersQuery);

    // Store the data of assigned users in an array
    const assignedUsersData = [];
    assignedUsersSnapshot.forEach((doc) => {
      assignedUsersData.push(doc.data());
    });

    // Log the data of assigned users
    console.log("Assigned users:", assignedUsersData);

    // Return the data of assigned users
    return assignedUsersData;
  } catch (error) {
    console.error("Error fetching assigned users:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};