import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "../firebase";

export const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  return (
    <center>
      <div className="absolute-center">
        <div className="all-center mt-5 ">
          <img
            src="/assets/logo.png"
            className="app-logo col-sm-4"
            alt="logo"
          />
          <div className="box">
            {/* <button className="btn btn-signup btn-primary m-2">
                        <Link to="/auth/signup">Sign Up</Link>
                    </button> */}

            <button className="btn btn-login btn-primary m-2">
              <Link to="/login">Sign In</Link>
            </button>
          </div>
          {/* <div id="coursesRedirect" className="form-text mt-3">
                    Access free courses <Link to="course">Here</Link>{" "}
                </div> */}
        </div>
      </div>
    </center>
  );
};
