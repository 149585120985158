import { React, useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth, updatePassword } from "firebase/auth";
import { db } from "../firebase";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import "../index.css";
/* eslint-disable */

const Profile = () => {
  const [photoUrl, setPhotoUrl] = useState("https://tinyurl.com/4ypa9c6z");
  const [userName, setUserName] = useState("User Name");
  const [userEmail, setUserEmail] = useState("User Email");
  const [userCoupon, setUserCoupon] = useState("Coupon Code");
  const [userPAN, setUserPAN] = useState("");

  const [warn, setWarn] = useState("");
  const [user, setUser] = useState({});

  const [authUser, setAuthUser] = useState(null);

  // Authentication
  const [userType, setUserType] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [makeRequest, setMakeRequest] = useState(false);


  const navigate = useNavigate();
  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS.split(",");
  const salesEmails = process.env.REACT_APP_SALES_EMAILS.split(",");
  const opsEmails = process.env.REACT_APP_OPS_EMAILS.split(",");
  // const employeeEmails = process.env.REACT_APP_EMPLOYEE_EMAILS.split(",");

  useEffect(() => {
    // const unsubscribe = onAuthStateChanged(auth, async (user) => {
    //   if (!user) {
    //     return navigate("/login");
    //   }
    //   const userEmail = user.email;
    //   if (user.photoURL) {
    //     setPhotoUrl(user.photoURL);
    //   }

    //   const isAdmin = adminEmails.includes(userEmail);
    //   if (isAdmin) {
    //     setUser(user);
    //     setUserEmail(user.email);
    //     setUserName(user.displayName);
    //   } else {
    //     auth
    //       .signOut()
    //       .then(() => {
    //         alert(
    //           "You are not authorized to access this page. Please contact the administrator."
    //         );
    //         return navigate("/login");
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // });
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        return navigate("/login");
      }
      const userEmail = user.email;
      setUserName(pan);
      const AdminId = adminEmails.includes(userEmail);
      const isSales = salesEmails.includes(userEmail);
      const isOps = opsEmails.includes(userEmail);
      // const isEmployee = employeeEmails.includes(userEmail);
      if (AdminId || isSales || isOps) {
        setUserName(user.displayName);
        setUser(user);
        if(AdminId) {
          setUserType("ADMIN");
          setIsAdmin(true);
        } else if(isSales){
          setUserType("SALES");
          setIsAdmin(false);
        }
        else{
          setUserType("OPERATIONS");
          setIsAdmin(false);
        }
        setMakeRequest(true);
      } 
      else {
        auth
          .signOut()
          .then(() => {
            alert(
              "Profile You are not authorized to access this page. Please contact the administrator."
            );
            return navigate("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    return () => unsubscribe;
  }, [navigate]);

  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };

  const [notice, setNotice] = useState("");

  const handleSubmitNotice = async (e) => {
    e.preventDefault();
    if (!notice) {
      newWarn("Notice empty.");
      return;
    }
    const obj = {
      notice: notice,
    };
    const docRef = doc(db, "notices", "notice");

    setDoc(docRef, obj)
      .then(() => {
        newWarn("Notice added succesfully.");
        return;
      })
      .catch((error) => {
        newWarn("Notice not added (Error).");
        console.log(error);
      });

    setNotice("");
  };

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const reset = (e) => {
    e.preventDefault();
    if (!password) {
      newWarn("Password Empty");
      return;
    }

    if (password !== newPassword) {
      newWarn("Password and Confirm Password must be same!");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    updatePassword(user, newPassword)
      .then(() => {
        newWarn("Password updated successfully.");
        setPassword("");
        setNewPassword("");
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(error.code);
        newWarn(errorMessage);
      });
  };

  const generateCoupon = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "coupon", userPAN);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      newWarn("Coupon Already Generated.");
      return;
    } else {
      // setLoading(false);
    }
    function generateCouponCode(panNumber) {
      const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

      if (!PAN_REGEX.test(panNumber)) {
        newWarn("Enter correct PAN number.");
        return;
      }

      const randomString = generateRandomString(10);

      const couponCode = panNumber + "-" + randomString;

      return couponCode;
    }

    function generateRandomString(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let randomString = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
      }

      return randomString;
    }

    try {
      const coupon = generateCouponCode(userPAN);
      setUserCoupon(coupon);
      const obj = {
        count: 0,
        usedBy: "",
        coupon: coupon,
      };
      const docRef = doc(db, "coupon", userPAN);

      setDoc(docRef, obj)
        .then(() => {
          return;
        })
        .catch((error) => {
          newWarn("Error.");
          console.log(error);
        });

      newWarn("Coupon Generated.");
    } catch (error) {
      newWarn("Error Occured. Check PAN Number.");
      console.error(error.message);
      return;
    }
  };

  const userSignOut = () => {
    signOut(auth)
      .then(() => {})
      .catch((error) => console.log(error));
  };

  return (
    <div className="p-3">
      <div className="container-fluid">
        <div className="top-nav">
          <Link to="/dashboard">
            <img src="/assets/logo.png" alt="logo" className="logo" />
          </Link>
        </div>
      </div>

      <div className="text-center mt-5">
        <img
          src={photoUrl}
          className="img-thumbnail rounded-circle"
          alt="user avatar"
          width="100"
          height={200}
        ></img>
        <p className="mt-3">
          <b>{userName}</b> | Admin
        </p>
      </div>

      <div class="d-flex justify-content-center mt-5">
        {user ? (
          <>
            <button onClick={userSignOut} className="btn btn-danger m-2">
              Sign Out
            </button>
          </>
        ) : (
          <p>Signed Out</p>
        )}
      </div>

      <div class="d-flex justify-content-center mt-5">
        <div class="mb-3 col-4 ">
          <label for="exampleFormControlInput1" class="form-label">
            Email
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder={userEmail}
            disabled
            readOnly
          />
        </div>
      </div>

      {warn && (
        <div class="d-flex justify-content-center mt-5">
          <div className="alert alert-info mt-1 col-7" role="alert">
            {warn}
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center mt-5">
        <div
          className="acc accordion col-4 mr-3"
          id="accordionExample1"
          style={{ marginRight: 20 + "px" }}
        >
          <div className="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
            <h2 className="accordion-header">
              <button
                className="accordion-button  text-light  rounded-pill"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Change Password
              </button>
            </h2>

            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="d-flex justify-content-center mt-5">
                <form className="col-10" onSubmit={reset}>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control rounded-pill"
                      id="exampleInputEmail1"
                      placeholder="New Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control rounded-pill"
                      id="exampleInputPassword1"
                      placeholder="Confirm Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-danger m-2">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="acc accordion col-4 ml-3"
          id="accordionExample2"
          style={{ marginLeft: 20 + "px" }}
        >
          <div class="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
            <h2 class="accordion-header">
              <button
                class="accordion-button text-light rounded-pill"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Add Notice
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div class="d-flex justify-content-center mt-5">
                <form class="col-10" onSubmit={handleSubmitNotice}>
                  <div class="mb-3">
                    <label
                      for="exampleFormControlTextarea1"
                      style={{ color: "white" }}
                      class="form-label"
                    >
                      Notice
                    </label>
                    <textarea
                      class="form-control rounded-pill"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={notice}
                      onChange={(e) => setNotice(e.target.value)}
                    ></textarea>
                  </div>
                  <button type="submit" class="btn btn-danger m-2">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-5">
        <div
          class="acc accordion col-4 ml-3"
          id="accordionExample2"
          style={{ marginLeft: 20 + "px" }}
        >
          <div class="accordion-item my-3 rounded-5 border-0 bg-dark-gray">
            <h2 class="accordion-header" style={{ color: "white" }}>
              <button
                class="accordion-button  text-light  rounded-pill"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Generate Coupon
              </button>
            </h2>

            <div
              id="collapseThree"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div class="d-flex justify-content-center mt-5">
                <form class="col-10" onSubmit={generateCoupon}>
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control rounded-pill"
                      id="exampleInputEmail1"
                      placeholder="PAN Number"
                      pattern="[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}"
                      onChange={(e) => setUserPAN(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control rounded-pill"
                      id="exampleInputPassword1"
                      placeholder={userCoupon}
                      disabled
                      readOnly
                    />
                  </div>
                  <button type="submit" class="btn btn-danger m-2">
                    Generate
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
