import React from "react";

export const YearSelector = ({ setYear, year }) => {
  return (
    <div className="dropdown">
      <button
        className="btn year-drop d-flex btn-secondary dropdown-toggle dropdown-toggle-no-caret "
        style={{ background: "#454545" }}
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        AY {year}{" "}
        <span className="dropdown-logo">
          <img src="/assets/logo/drop.png"></img>{" "}
        </span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              setYear("2022-23");
            }}
          >
            AY 2022-23
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              setYear("2021-22");
            }}
          >
            AY 2021-22
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              setYear("2020-21");
            }}
          >
            AY 2020-21
          </button>
        </li>
      </ul>
    </div>
  );
};
