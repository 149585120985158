import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Link } from "react-router-dom";


export const DownloadData = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      setUserData(data);
    };

    fetchData();
  }, []);

  const headers = [
    { label: "Name", key: "name" },
    { label: "PAN", key: "pan" },
    { label: "Email", key: "email" },
    { label: "ITRType", key: "itrType" },
    { label: "RoleSet", key: "roleSet" },
    { label: "City", key: "city" },
    { label: "Area", key: "area" },
    { label: "Fees", key: "Fees" },
    { label: "PaidFees", key: "PaidFees" },
    { label: "PendingFees", key: "PendingFees" },
    { label: "Assigned To", key: "assign" },
  ];

  return (
    <div className="p-1 d-flex justify-content-end col">
      <button className="btn btn-dark border-0 rounded-pill py-2 px-4 text-decoration-none" style={{ margin: "0 4px 0 0" }}>
        <Link to="/addUser"><span style={{ textDecoration: "none", color: "white" }}>
            ADD USER
        </span>
        </Link>
      </button>
      <button className="btn btn-dark border-0 rounded-pill py-2 px-4 text-decoration-none">
        <CSVLink data={userData} headers={headers} filename="user_data.csv">
          <span style={{ textDecoration: "none", color: "white" }}>
            DOWNLOAD
          </span>
        </CSVLink>
      </button>
    </div>
  );
};
