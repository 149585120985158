import {
  collection,
  query,
  onSnapshot,
  where,
  startAfter,
  // limit,
  getDocs,
  orderBy,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "../firebase";
import { Navigate } from "react-router-dom";

export const updateRemark = async (userPAN, remark) => {
  console.log(remark);
  const docRef = doc(db, "users", userPAN);
  try {
    await updateDoc(docRef, {remark:remark});
    alert("Remark updated ");
  } catch (error) {
    alert("Error in updation")
    console.log("Error: ", error);
  }
}