import { React, useState, useEffect, useRef } from "react";
import "./dash.css";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  onSnapshot,
  where,
  startAfter,
  limit,
  getDocs,
  doc,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import { DownloadData } from "../components/downloadData";
import {
  fetch,
  fetchNext,
  fetchPunchCount,
  fetchUserCount,
  fetchAssignedcount
} from "../services/fetch";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { TopNav } from "./blocks/topNav";
import Table from "./Table";
import { Remark } from "./remark";
import { Query } from "./queries";
import { ThemeContext } from "@emotion/react";
// import { getAllAsigned } from "../services/assign";

const Dashboard = () => {
  const [userName, setUserName] = useState("User Name");
  const [activateStatus, setActivatestatus] = useState("users");
  const scrollRef = useRef(null);
  const [currPage, setCurrPage] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [lastResult, setLastResult] = useState(false);
  const [assignedCount, setAssignedcount] = useState(0);
  const [punched, setPunched] = useState(false);
  const [pan, setPan] = useState();
  const [userCount, setUserCount] = useState(0);
  const [punchCount, setPunchCount] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [userType, setUserType] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [isOps, setIsOps] = useState(false);
  const [makeRequest, setMakeRequest] = useState(false);
  const [isAssigned, setIsassigned] = useState(false);
  // const [assign, setAssign] = useState({});
  const [remarkData, setRemarkdata] = useState("");
  const [showRemark, setShowremark] = useState(false);
  const [currentRemarkPAN, setCurrentremarkPAN] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [reload, setReload] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [allQueries, setAllqueries] = useState(false);

  const [assignedUsersCount, setAssignedUsersCount] = useState(0);

  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS.split(",");
  const salesEmails = process.env.REACT_APP_SALES_EMAILS.split(",");
  const opsEmails = process.env.REACT_APP_OPS_EMAILS.split(",");

  const getRemarkdata = (state, remarkValue, userPAN) => {
    setRemarkdata(remarkValue);
    setShowremark(state);
    setCurrentremarkPAN(userPAN);
  }

  const closeRemark = (closeState) => {
    setRemarkdata("");
    setShowremark(closeState);
    setCurrentremarkPAN("");
  }

  console.log("Assigned Users Count :", assignedUsersCount);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("User object:", user);
      console.log("Display Name:", user.displayName);
      if (!user) {
        return navigate("/login");
      }
      // const userEmail = user.email;
      // setUserEmail(userEmail);
      // // alert(user.displayName);
      // setUserName(pan);
      // const AdminId = adminEmails.includes(userEmail);
      // const isSales = salesEmails.includes(userEmail);
      // const isOps = opsEmails.includes(userEmail);
      // const isEmployee = employeeEmails.includes(userEmail);

      console.log("user is.....", user);
      const userEmail = user.email;
      // console.log("current useremail is :", userEmail);
      setUserName(user.displayName || "User Name");


      const lowerCaseUserEmail = userEmail.toLowerCase();
      const AdminId = adminEmails.includes(lowerCaseUserEmail);
      const salesId = salesEmails.some((salesEmails) =>
        userEmail.startsWith(salesEmails.toLowerCase()));

      const OpsId = opsEmails.some((opsEmails) =>
        userEmail.startsWith(opsEmails.toLowerCase()));

      if (AdminId || salesId || OpsId) {
        setUserName(user.displayName);
        console.log("display name is ....", user.displayName);
        setUser(user);
        if(AdminId) {
          setUserType("ADMIN");
          setIsAdmin(true);
        }else if(salesId) {
          setUserType("SALES");
          setIsSales(true);
        }else if(OpsId) {
          
          setUserType("OPERATION");
          setIsOps(true);
        }
        setMakeRequest(true);
      } 
      else {
        auth
          .signOut()
          .then(() => {
            alert(
              "Dashboard You are not authorized to access this page. Please contact the administrator."
            );
            return navigate("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    return () => unsubscribe;
  }, [navigate]);


  useEffect(() => {
    makeRequest?fetchData():"";
    setReload(false);
  }, [makeRequest, userEmail, reload]);

  useEffect(() => {
    if(makeRequest) {
      setTasks([]);
      setLastResult(false);
      setLastVisible(null);
      fetchData(punched);
    }
  }, [punched, makeRequest, isAssigned, userEmail]);

  useEffect(() => {
    if(makeRequest) {
      fetchOnScroll(punched);
    }
  }, [currPage, prevPage, lastResult, makeRequest]);


  
  // const fetchAssign = async() => {
  //   setAssign(getAllAsigned())
  // }

  // useEffect(() => {
  //   // fetchAssign();
  // })

  const fetchData = async (punched) => {
    const { newFetch: data, lastVisible } = await fetch(user.email, punched, isAdmin, isSales, isOps, isAssigned);
    // const { newFetch: data, lastVisible } = await fetch(punched);


    // const updatedData = await Promise.all(
    //   data.map(async (user) => {
    //     // Fetch additional data for each user (replace 'users' with your collection name)
    //     const userDoc = await getDoc(doc(db, "users", user.uid));
    //     const userData = userDoc.data();
  
    //     return {
    //       ...user,
    //       fees: userData.fees,
    //       paid: userData.paid,
    //       pendingFees: userData.pendingFees,
    //     };
    //   })
    // );

    const users = await fetchUserCount(userEmail, isAdmin, isSales, isOps);
    const punch = await fetchPunchCount(userEmail, isAdmin, isSales, isOps);
    const assignedCount = await fetchAssignedcount();
    setAssignedcount(assignedCount);
    setUserCount(users);
    setPunchCount(punch);
    setTasks(data);
    setLastVisible(lastVisible);
    setCurrPage(0);
    setPrevPage(0);
  };

  const fetchOnScroll = async (punched) => {
    if (currPage !== prevPage && !lastResult) {
      const { newTasks, lastVisibleNext, lastResponse } = await fetchNext(
        user.email,
        tasks,
        lastVisible,
        punched,
        isAdmin,
        isSales,
        isOps
      );
      setTasks(newTasks);
      setPrevPage(currPage);
      setLastVisible(lastVisibleNext);
      setLastResult(lastResponse);
    }
  };

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 1 &&
        currPage === prevPage
      ) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const showAllUsers = async () => {
    setActivatestatus("users");
    setIsassigned(false);
    setPunched(false);
    
  };
  const showAssignedUsers = async () => {
    setActivatestatus("assigned");
    setIsassigned(true);
    setPunched(false);
  }
  const showPunchedUsers = async () => {
    setActivatestatus("punched");
    // setPunched(!punched);
    setPunched(true);
    setIsassigned(false);
  };
  const showQueries = async () => {
    setAllqueries(true);
  }

  const closeQueryWindow = () => {
    setAllqueries(false);
  }

  useEffect(() => {
    const filterTasks = () => {
      const query = searchQuery.toLowerCase();
      const filtered = tasks.filter(
        (task) =>
          task.name.toLowerCase().includes(query) ||
          task.pan.toLowerCase().includes(query) ||
          task.mobile.includes(query)
      );
      setFilteredTasks(filtered);
    };

    filterTasks();
  }, [searchQuery, tasks]);

  
  // const searchHandle = async () => {
  //   // setTasks([]);
  //   // const searchQuery = query(collection(db, "users"), where("pan", "==", pan));
  //   // onSnapshot(searchQuery, (querySnapshot) => {
  //   //   setTasks(querySnapshot.docs.map((doc) => doc.data()));
  //   // });
  //   if (!searchTerm) {
  //     setFilteredTasks([]);
  //     return;
  //   }

  //   const filteredTasksByName = tasks.filter(task =>
  //     task.name.toLowerCase().includes(searchTerm.toLowerCase())
  //   );

  //   const filteredTasksByPan = tasks.filter(task =>
  //     task.pan.toUpperCase().includes(searchTerm.toUpperCase())
  //   );

  //   const filteredTasksByMobile = tasks.filter(task =>
  //     task.mobile.includes(searchTerm)
  //   );

  //   const combinedFilteredTasks = [
  //     ...filteredTasksByName,
  //     ...filteredTasksByPan,
  //     ...filteredTasksByMobile
  //   ];

  //   setFilteredTasks(combinedFilteredTasks);
  // };

  // useEffect(() => {
  //   setFilteredTasks(tasks);
  // }, [tasks]);

  const AssignedComponent = () => {
    return (
      <div
        className={`col-md-3 users-count assigned-section ${activateStatus=="assigned" ? "active-user" : ""}`}
        onClick={showAssignedUsers}
        // Modify showAllUsers to assigned
      >
        <img src="/assets/assigned.svg" alt="users" className="users-logo" />
        <div className="users-data">
          <h1>{assignedCount}</h1>
          <p>&nbsp; Assigned</p>
        </div>
      </div>
    )
  }

  return (
    <div className="p-3 px-5">
      {showRemark?<Remark remark={remarkData} currentUser={currentRemarkPAN} closeRemark={closeRemark} reloadPage={() => setReload(true)}/>:""}
      {allQueries?<Query closeQueryWindow={closeQueryWindow}/>:""}
      <div className="container-fluid">
        <TopNav userName={userName} userType={userType}/>
        <div className="row mt-3">
          <div
            className={`${isAdmin || isSales || isOps?"col-md-3":"col-md-4"} users-count users-section ${activateStatus=="users" ? "active-user" : ""}`}
            onClick={showAllUsers}
          >
            <img src="/assets/users.png" alt="users" className="users-logo" />
            <div className="users-data">
              <h1>{isOps ? assignedUsersCount : userCount}</h1>
              <p>&nbsp; Users</p>
            </div>
          </div>
          {isAdmin || isSales?<AssignedComponent/>:null}
          <div
            className={`${isAdmin || isSales || isOps?"col-md-3":"col-md-4"} users-count pusers ${
              activateStatus=="punched" ? "active-user" : ""
            }`}
            onClick={showPunchedUsers}
          >
            <img src="/assets/punched.png" alt="users" className="users-logo" />
            <div className="users-data">
              <h1>{punchCount}</h1>
              <p>&nbsp; Punched</p>
            </div>
          </div>
          <div className="col-md-2 download-box" onClick={showQueries}>
            <Link>
              <img
                src="/assets/queries.png"
                style={{ width: "100px" }}
                alt="arrow"
                className="download-logo"
              />
              <br />
              <button>Queries</button>
            </Link>
          </div>
          <div className={`${isAdmin || isSales || isOps?"col-md-1":"col-md-2"} download-box`}>
            <Link to="/punchedusers">
              <img
                src="/assets/upload.png"
                style={{ width: "100px" }}
                alt="arrow"
                className="download-logo"
              />
              <br />
              <button>Upload</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row pt-3 pb-3">
        <div className="col d-flex rounded-pill border p-1">
          <input
            name="searchTerm"
            type="text"
            // pattern="[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}"
            // value={pan}
            // onChange={(e) => setPan(e.target.value)}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="form-control border-0 rounded-pill"
            placeholder="Search PAN"
            required
          />
        </div>
        {isAdmin || isSales || isOps? <DownloadData/>: null}
      </div>
      {/* <button onClick={fetch}>Fetch</button> */}
      {/* <button onClick={downloadData}>Download Data</button> */}
      {/* <CSVLink {...csvreport}>Export to CSV</CSVLink> */}
      {/* <div
        className="container-fluid table-holder"
        onScroll={onScroll}
        ref={scrollRef}
      > */}
      {/* <table
          className="table"
          style={{ borderCollapse: "separate", borderSpacing: "0.5em 0.5em" }}
        >
          <thead>
            <tr>
              <th>SR NO</th>
              <th>NAME</th>
              <th>JOB</th>
              <th>EMAIL</th>
              <th>PAN</th>
              <th>MOBILE</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((data, index) => {
              const color = data.isVerified ? "verified" : "";
              return (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ paddingLeft: "20px" }}>{data?.name}</td>
                  <td style={{ textAlign: "center" }}>{data?.roleSet}</td>
                  <td style={{ paddingLeft: "20px" }}>{data?.email}</td>
                  <td style={{ textAlign: "center" }}>{data?.pan}</td>
                  <td style={{ textAlign: "center" }}>{data?.mobile}</td>
                  <td
                    className={color}
                    style={{ color: "white", textAlign: "center" }}
                  >
                    {color.toLocaleUpperCase()}
                  </td>
                  <td className="view-profile-button">
                    <Link to={/user/${data?.uid}}>View</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
      {/* </div> */}
      <Table tasks={filteredTasks} setTasks={setTasks} userType={userType} employeeDetails={salesEmails} getRemarkdata={getRemarkdata} userEmail={user.email} setAssignedUsersCount={setAssignedUsersCount}/>
      <br />
    </div>
  );
};

export default Dashboard;