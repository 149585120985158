import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

const SignIn = () => {
  const [warn, setWarn] = useState("");
  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        return navigate("/dashboard", { replace: true });
      }
    });
    return () => {
      listen();
    };
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Check admin collection
    const adminDocRef = doc(db, "admin", email);
    const adminDocSnap = await getDoc(adminDocRef);
  
    // Check sales collection
    const salesDocRef = doc(db, "sales", email);
    const salesDocSnap = await getDoc(salesDocRef);

    const opsDocRef = doc(db, "ops", email);
    const opsDocSnap = await getDoc(opsDocRef);
  
    if (
      !(
        (adminDocSnap.exists() && adminDocSnap.data().admin) ||
        (salesDocSnap.exists() && salesDocSnap.data().sales) ||
        (opsDocSnap.exists() && opsDocSnap.data().ops)
      )
    ) {
      setLoading(false);
      newWarn("Invalid user.");
      return;
    }

    console.log("Admin Data:", adminDocSnap.exists() ? adminDocSnap.data() : null);
    console.log("Sales Data:", salesDocSnap.exists() ? salesDocSnap.data() : null);
    console.log("Ops Data:", opsDocSnap.exists() ? opsDocSnap.data() : null);
  
    // Assuming emailPAN is a field in both admin and sales collections
    const emailPAN =
    adminDocSnap.exists()
      ? adminDocSnap.data().emailPAN : salesDocSnap.exists() ? salesDocSnap.data().emailPAN : opsDocSnap.exists() ? opsDocSnap.data().emailPAN : null;

    console.log(emailPAN);
  

      
    if (!emailPAN) {
      setLoading(false);
      newWarn("Invalid user details.");
      return;
    }

    signInWithEmailAndPassword(auth, emailPAN, password)
      .then((userCredential) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        newWarn(error.message);
      });
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="signin-page mt-5 p-4 col-6">
        <form onSubmit={signIn}>
          <center>
            <img
              src="/assets/logo.png"
              className="app-logo-sm col-6"
              alt="logo"
            />
            <h1 className="mt-2">Log In</h1>
          </center>
          {warn && (
            <div className="alert alert-warning mt-1" role="alert">
              {warn}
            </div>
          )}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-signup mt-3"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Log_In"}
          </button>
          <div id="emailHelp" className="form-text mt-3">
            {/* 'Dont_have_an_account' <Link to="/auth/signup">Register</Link>{" "} */}
          </div>
          <div id="emailHelp" className="form-text mt-3">
            {/* <Link to="/auth/reset">'Forgot_Password'</Link>{" "} */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
